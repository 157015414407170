import React, { FC } from 'react'
import { InsightQueryTable } from '../organisms'
import {
    ExportFormat,
    InsightQueryDTO,
    colors,
} from '@hazadapt-git/public-core-base'
import IconButton from '@mui/material/IconButton'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'
import { makeStyles } from 'tss-react/mui'
import { useStyles } from '../../lib/styles/universal'
import { NamespacedPageProps } from '../../lib/entities'
import { LoadingView } from '../molecules'
import { IoPrint } from 'react-icons/io5'
import { useWindowSizeUp } from '../../lib/utils'
import classNames from 'classnames'
import { UseReactToPrintFn } from 'react-to-print'

interface SavedQueriesPageTemplateProps extends NamespacedPageProps {
    onGenerateReport(id: number, format?: ExportFormat): void
    onPrint: UseReactToPrintFn
    contentToPrint: React.RefObject<HTMLDivElement>
    queries?: InsightQueryDTO[]
    page: number
    numPages: number
    onPaginate(e: React.ChangeEvent<unknown>, page: number): void
}

export const SavedQueriesPageTemplate: FC<SavedQueriesPageTemplateProps> = (
    props: SavedQueriesPageTemplateProps
) => {
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const mdScreens = useWindowSizeUp('md')

    return props.organization && props.user ? (
        <div
            className={classNames(classes.body, localClasses.container)}
            ref={props.contentToPrint}
        >
            <div className={localClasses.headerContainer}>
                <Typography component="h1" variant={mdScreens ? 'h2' : 'h3'}>
                    Saved Queries
                </Typography>
                <IconButton
                    onClick={() => props.onPrint()}
                    disabled={props.queries?.length === 0}
                    aria-label="Print current page of saved queries"
                >
                    <IoPrint color={colors.grays.NOIR} />
                </IconButton>
            </div>
            <div className={localClasses.insightQueryTableWrapper}>
                <InsightQueryTable
                    onDownloadReportPress={props.onGenerateReport}
                    queries={props.queries}
                />
                {props.numPages > 1 ? (
                    <Pagination
                        count={props.numPages}
                        page={props.page}
                        onChange={props.onPaginate}
                        siblingCount={1}
                        className={localClasses.pagination}
                    />
                ) : null}
            </div>
        </div>
    ) : (
        <LoadingView />
    )
}

const useLocalStyles = makeStyles()({
    container: {
        padding: '1rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'flex-start',
    },
    headerContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '1rem',
    },
    pagination: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 auto',
    },
    insightQueryTableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        alignItems: 'flex-start',
        width: 'calc(100% - 2rem)',
    },
})
