import React, { FC, useEffect } from 'react'
import { NamespacedPageProps } from '../lib/entities'
import { Navigate, Route, Routes, useLocation } from 'react-router'
import { getSubscriptions } from '../lib/utils'
import { LoadingView } from '../components'
import { SubscriptionsOverview } from '../components/organisms/SubscriptionsOverview'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { ManageSubscriptionsPage } from './ManageSubscriptions'
import { useOrganization } from '../lib/hooks/useOrganization'
import { colors } from '@hazadapt-git/public-core-base'

interface SubscriptionsAndBillingPageProps extends NamespacedPageProps {}

const NewPaymentMethodPage = React.lazy(() => import('./NewPaymentMethod'))
export const SubscriptionsAndBillingPage: FC<
    SubscriptionsAndBillingPageProps
> = ({ user, loading }) => {
    const { organization } = useOrganization()
    const location = useLocation()

    useEffect(() => {
        getSubscriptions()
    }, [])

    React.useEffect(() => {
        document.title = 'Subscriptions and Billing - ResiliencePoint'
    }, [])

    if (!user || !organization) {
        return !loading && (user === null || organization === null) ? null : (
            <LoadingView />
        )
    }

    return (
        <Stack gap={'2rem'}>
            {location.pathname.includes('/payment-methods') ? null : (
                <Stack gap={'.5rem'} maxWidth={'34rem'}>
                    <Typography variant="body1" fontWeight={500}>
                        Make changes to your subscriptions or add seats here.
                    </Typography>
                    <Typography variant="body2" color={colors.grays.CHARCOAL}>
                        Please note if you would like to make changes to a
                        different organization, you must be logged in to the
                        organization.
                    </Typography>
                </Stack>
            )}
            <Routes>
                <Route
                    path=""
                    element={
                        <SubscriptionsOverview
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    }
                />
                <Route
                    path="payment-methods/new"
                    element={
                        <NewPaymentMethodPage
                            user={user}
                            organization={organization}
                            loading={loading}
                        />
                    }
                />
                <Route
                    path="core/*"
                    element={
                        <ManageSubscriptionsPage
                            subscriptionType="core"
                            organization={organization}
                        />
                    }
                />
                <Route
                    path="seats/*"
                    element={
                        <ManageSubscriptionsPage
                            subscriptionType="seat"
                            organization={organization}
                        />
                    }
                />
                <Route
                    path="*"
                    element={<Navigate replace to="/settings/subscriptions" />}
                />
            </Routes>
        </Stack>
    )
}
