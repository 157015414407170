import React, { CSSProperties, useCallback, useMemo } from 'react'
import { resetStripeOptions, selectSubscriptions } from '../slices/payment'
import { store, useAppSelector } from '../store'
import { formatStripeTimestamp } from '../utils'
import Amex from '../../assets/icons/Amex.png'
import ApplePay from '../../assets/icons/ApplePay.svg'
import CashApp from '../../assets/icons/CashApp.svg'
import Discover from '../../assets/icons/Discover.png'
import GooglePay from '../../assets/icons/GooglePay.svg'
import Mastercard from '../../assets/icons/Mastercard.svg'
import PayPal from '../../assets/icons/PayPal.png'
import Visa from '../../assets/icons/Visa.png'
import { PiBank } from 'react-icons/pi'
import Stripe from 'stripe'
import {
    PaymentIntentResult,
    PaymentMethodResult,
    StripeElements,
    Stripe as StripeJs,
} from '@stripe/stripe-js'
import { RPPaymentMethod } from '../entities'
import { colors } from '@hazadapt-git/public-core-base'

export interface SimplifiedSeatSubscription {
    id: string
    status: Stripe.Subscription.Status
    renewalDate: string | null
    purchaseDate: string | null
    cancelDate: string | null
    seatCount: number
}

export const useBilling = () => {
    const { subscriptions, payment_methods } =
        useAppSelector(selectSubscriptions)

    // Root subscription
    const rootSubscription = useMemo(
        () =>
            subscriptions?.find(
                (sub) => sub.metadata.subscription_type === 'core'
            ),
        [subscriptions]
    )

    const rootSubscriptionEndDate = useMemo(
        () =>
            rootSubscription
                ? formatStripeTimestamp(rootSubscription.current_period_end)
                : 'N/A',
        [rootSubscription]
    )

    // Add-on subscriptions
    const addOnSubscriptions = useMemo(
        () =>
            subscriptions?.filter(
                (sub) =>
                    sub.metadata.subscription_type === 'add-on' &&
                    sub.metadata.status === 'active'
            ),
        [subscriptions]
    )

    // Seat subscriptions
    const seatSubscriptions = useMemo(
        () =>
            subscriptions?.filter((sub) =>
                sub.items.data.find(
                    (item) => item.metadata.add_on_type === 'seat'
                )
            ),
        [subscriptions]
    )

    const simplifiedSeatSubscriptions = useMemo(() => {
        return (
            seatSubscriptions
                ?.map((sub) => {
                    const seatItem = sub.items.data.find(
                        (item) => item.metadata.add_on_type === 'seat'
                    )

                    return {
                        id: sub.id,
                        status: sub.status,
                        renewalDate: formatStripeTimestamp(
                            sub.current_period_end
                        ),
                        purchaseDate: formatStripeTimestamp(
                            sub.current_period_start
                        ),
                        cancelDate: formatStripeTimestamp(sub.ended_at),
                        seatCount: seatItem?.quantity ?? 0,
                    }
                })
                .filter((item) => item.seatCount > 0) || []
        )
    }, [seatSubscriptions])

    const unresolvedSeatCount = useMemo(() => {
        let seatCount = 0
        if (seatSubscriptions) {
            for (const sub of seatSubscriptions) {
                if (sub.metadata.status === 'cancelled') {
                    const unresolved = sub?.metadata.unresolved
                    if (unresolved && unresolved !== '{}') {
                        try {
                            const parsed = JSON.parse(unresolved)
                            if (parsed.seats > 0) {
                                seatCount += parsed.seats
                            }
                        } catch (err) {
                            console.error('Error parsing unresolved data:', err)
                        }
                    }
                }
            }
        }
        return seatCount
    }, [seatSubscriptions])

    // Payment method management
    const getPaymentMethodType = useCallback((pm: Stripe.PaymentMethod) => {
        switch (pm.type) {
            case 'card':
                //Google/Apple Pay are still card payments and include a wallet object
                if (pm.card?.wallet) {
                    if (pm.card?.wallet.type === 'apple_pay') {
                        return 'Apple Pay'
                    } else if (pm.card?.wallet.type === 'google_pay')
                        return 'Google Pay'
                }
                return pm.card?.funding === 'debit'
                    ? 'Debit Card'
                    : 'Credit Card'
            case 'cashapp':
                return 'Cash App Pay'
            case 'paypal':
                return 'PayPal'
            case 'us_bank_account':
                return 'Bank Account'
            default:
                return ''
        }
    }, [])

    const getPaymentMethodLast4 = useCallback((pm: Stripe.PaymentMethod) => {
        if (pm.card) {
            return pm.card.last4
        } else if (pm.us_bank_account) {
            return pm.us_bank_account.last4
        }
        return null
    }, [])

    const getPaymentMethodExpirationDate = useCallback(
        (pm: Stripe.PaymentMethod) => {
            if (pm.type !== 'card' || !pm.card) return null
            return `${pm.card.exp_month.toString().padStart(2, '0')}/${
                pm.card.exp_year
            }`
        },
        []
    )

    const getPaymentMethodIcon = useCallback(
        (
            pm: Stripe.PaymentMethod,
            paymentMethodType: string | null
        ): React.ReactNode => {
            if (!paymentMethodType) return null
            const iconDims: CSSProperties = {
                width: '4rem',
                height: 'auto',
            }

            switch (paymentMethodType) {
                case 'Apple Pay':
                    return (
                        <img src={ApplePay} alt="Apple Pay" style={iconDims} />
                    )
                case 'Google Pay':
                    return (
                        <img
                            src={GooglePay}
                            alt="Google Pay"
                            style={iconDims}
                        />
                    )
                case 'Bank Account':
                    return <PiBank size="4rem" color={colors.grays.CHARCOAL} />
                case 'Cash App Pay':
                    return <img src={CashApp} alt="Cash App" style={iconDims} />
                case 'PayPal':
                    return <img src={PayPal} alt="PayPal" style={iconDims} />
                case 'Debit Card':
                case 'Credit Card':
                    switch (pm.card?.brand) {
                        case 'visa':
                            return (
                                <img src={Visa} alt="Visa" style={iconDims} />
                            )
                        case 'mastercard':
                            return (
                                <img
                                    src={Mastercard}
                                    alt="Mastercard"
                                    style={iconDims}
                                />
                            )
                        case 'amex':
                            return (
                                <img
                                    src={Amex}
                                    alt="American Express"
                                    style={iconDims}
                                />
                            )
                        case 'discover':
                            return (
                                <img
                                    src={Discover}
                                    alt="Discover"
                                    style={iconDims}
                                />
                            )
                        default:
                            return null
                    }
                default:
                    return null
            }
        },
        []
    )

    const paymentMethods = useMemo(() => {
        const methods: RPPaymentMethod[] = []
        for (const pm of payment_methods) {
            const paymentMethodType = getPaymentMethodType(pm)
            methods.push({
                repr: pm,
                id: pm.id,
                type: paymentMethodType,
                last4: getPaymentMethodLast4(pm),
                icon: getPaymentMethodIcon(pm, paymentMethodType),
                date_created: pm.created,
            })
        }
        methods.sort((a, b) => b.date_created - a.date_created)
        return methods
    }, [
        getPaymentMethodIcon,
        getPaymentMethodLast4,
        getPaymentMethodType,
        payment_methods,
    ])

    const createPaymentMethod = useCallback(
        async ({
            stripe,
            clientSecret,
            elements,
            returnUrl,
        }: {
            stripe: StripeJs
            clientSecret: string
            elements: StripeElements
            returnUrl: string
        }): Promise<PaymentMethodResult> => {
            const result = await stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: returnUrl,
                },
            })
            return result
        },
        []
    )

    const confirmPayment = useCallback(
        async ({
            stripe,
            clientSecret,
            elements,
            selectedPaymentMethod,
            returnUrl,
        }: {
            stripe: StripeJs
            clientSecret: string
            elements?: StripeElements
            selectedPaymentMethod: Stripe.PaymentMethod | null
            returnUrl: string
        }): Promise<PaymentIntentResult> => {
            let result: PaymentIntentResult
            if (!selectedPaymentMethod) {
                result = await stripe.confirmPayment({
                    //`Elements` instance that was used to create the Payment Element
                    elements,
                    clientSecret,
                    confirmParams: {
                        return_url: returnUrl,
                    },
                })
            } else {
                switch (selectedPaymentMethod.type) {
                    case 'card': {
                        result = await stripe.confirmCardPayment(clientSecret, {
                            payment_method: selectedPaymentMethod.id,
                            return_url: returnUrl,
                        })
                        break
                    }
                    case 'cashapp': {
                        result = await stripe.confirmCashappPayment(
                            clientSecret,
                            {
                                payment_method: selectedPaymentMethod.id,
                                return_url: returnUrl,
                            }
                        )
                        break
                    }
                    case 'paypal': {
                        result = await stripe.confirmPayPalPayment(
                            clientSecret,
                            {
                                payment_method: selectedPaymentMethod.id,
                                return_url: returnUrl,
                            }
                        )
                        break
                    }
                    case 'us_bank_account': {
                        result = await stripe.confirmUsBankAccountPayment(
                            clientSecret,
                            {
                                payment_method: selectedPaymentMethod.id,
                                return_url: returnUrl,
                            }
                        )
                        break
                    }
                    default: {
                        result = {
                            error: {
                                type: 'card_error',
                                message: 'Invalid payment method',
                            },
                        }
                    }
                }
            }
            if (!result.error) {
                store.dispatch(resetStripeOptions())
                if (selectedPaymentMethod) {
                    const url = new URL(returnUrl)
                    url.searchParams.append(
                        'payment_intent',
                        result.paymentIntent.id
                    )
                    window.location.href = url.toString()
                }
            }
            return result
        },
        []
    )

    return {
        addOnSubscriptions,
        paymentMethods,
        rootSubscription,
        rootSubscriptionEndDate,
        seatSubscriptions,
        getPaymentMethodExpirationDate,
        simplifiedSeatSubscriptions,
        unresolvedSeatCount,
        confirmPayment,
        createPaymentMethod,
    }
}
