import React, { FC, useEffect, useMemo, useState } from 'react'

import {
    FullScreenInsightView,
    InsightView,
    InsightViewProps,
    QuickStatInsightViewProps,
} from '../molecules'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import { customBorderRadius } from '../../lib/styles/universal'
import { QuickStatInsightList } from './QuickStatInsightList'
import { insightHasData, useWindowSizeUp } from '../../lib/utils'

interface InsightGridProps {
    data?: InsightViewProps[]
    quickStatRowTitle?: string
}

export const InsightGrid: FC<InsightGridProps> = ({
    data,
    quickStatRowTitle,
}) => {
    const [fullScreen, setFullScreen] = useState<boolean>(false)
    const [currentInsightIndex, setCurrentInsightIndex] = useState<number>(0)
    const mdScreen = useWindowSizeUp('md')

    useEffect(() => {
        if (!mdScreen) setFullScreen(false)
    }, [mdScreen])

    useEffect(() => {
        // Disable background scrolling in fullscreen view
        document.body.style.overflow = fullScreen ? 'hidden' : ''
        return () => {
            document.body.style.overflow = ''
        }
    }, [fullScreen])

    const mapInsights = useMemo(
        () => data?.filter((d) => d.type === 'map') ?? [],
        [data]
    )

    const nonMapInsights = useMemo(
        () =>
            data?.filter((d) => d.type !== 'map' && d.type !== 'quick-stat') ??
            [],
        [data]
    )
    const quickStatInsights = useMemo(
        () => data?.filter((d) => d.type === 'quick-stat') ?? [],
        [data]
    )

    const handleOpenFullScreen = (insight: InsightViewProps, index: number) => {
        if (!insightHasData(insight) || !mdScreen) return
        setCurrentInsightIndex(index)
        setFullScreen(true)
    }

    return (
        <Box display="flex" flexDirection="column" gap="1rem">
            <Box
                display="grid"
                gridTemplateColumns="1fr"
                gap={{ xs: '1rem', md: '2rem' }}
            >
                {!data ? (
                    <>
                        <Skeleton
                            variant="rectangular"
                            width="100%"
                            sx={{
                                borderRadius: customBorderRadius,
                                height: {
                                    xs: '15rem',
                                    md: '20rem',
                                    xl: '25rem',
                                },
                            }}
                        />
                        <Box
                            display="flex"
                            gap="1rem"
                            width="100%"
                            overflow="hidden"
                        >
                            {Array.from(Array(4)).map((_, index) => (
                                <Skeleton
                                    variant="rectangular"
                                    height="10rem"
                                    width="100%"
                                    sx={{
                                        borderRadius: customBorderRadius,
                                        minWidth: {
                                            xs: '10rem',
                                            md: '14rem',
                                        },
                                    }}
                                    key={`quick-stat-insight-loader-${
                                        index + 1
                                    }`}
                                />
                            ))}
                        </Box>
                        <Grid
                            container
                            spacing={{ xs: '1rem', md: '2rem' }}
                            alignItems="stretch"
                        >
                            {Array.from(Array(6)).map((_, index) => (
                                <Grid
                                    item
                                    key={`insight-loader-${index + 1}`}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                >
                                    <Skeleton
                                        variant="rectangular"
                                        width="100%"
                                        height="20rem"
                                        sx={{
                                            borderRadius: customBorderRadius,
                                        }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </>
                ) : (
                    <>
                        {mapInsights.map((insight) => (
                            <Box
                                key={insight.placement_id}
                                borderRadius="0.5rem"
                                boxShadow="0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.35)"
                                overflow="hidden"
                            >
                                <InsightView {...insight} />
                            </Box>
                        ))}
                        <QuickStatInsightList
                            data={
                                quickStatInsights as QuickStatInsightViewProps[]
                            }
                            title={quickStatRowTitle}
                        />
                        <Grid
                            container
                            spacing={{ xs: '1rem', md: '2rem' }}
                            alignItems="stretch"
                        >
                            {nonMapInsights.map((insight, index) => (
                                <Grid
                                    item
                                    key={insight.placement_id}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                    xl={3}
                                >
                                    <Box
                                        height="100%"
                                        boxShadow="0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.35)"
                                        borderRadius="0.5rem"
                                    >
                                        <InsightView
                                            {...insight}
                                            onOpenFullScreen={() =>
                                                handleOpenFullScreen(
                                                    insight,
                                                    index
                                                )
                                            }
                                        />
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                        {fullScreen && (
                            <FullScreenInsightView
                                insights={nonMapInsights}
                                currentInsightIndex={currentInsightIndex}
                                onClose={() => setFullScreen(false)}
                            />
                        )}
                    </>
                )}
            </Box>
        </Box>
    )
}
