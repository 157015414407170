import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import Skeleton from '@mui/material/Skeleton'
import {
    Insight,
    PickerItem,
    SurveyAnswerInsight,
    SurveyAnswerInsightItem,
    colors,
    Organization,
    ZOOM_THRESHOLDS,
} from '@hazadapt-git/public-core-base'
import React, { FC, useMemo } from 'react'
import { makeStyles } from 'tss-react/mui'
import Link from '@mui/material/Link'
import {
    IoAdd,
    IoAlertCircleOutline,
    IoChevronBack,
    IoChevronForward,
    IoInformationCircleOutline,
} from 'react-icons/io5'
import {
    InsightView,
    InsightViewProps,
    LastUpdatedWithReload,
} from '../molecules'
import classNames from 'classnames'
import { useWindowSizeUp } from '../../lib/utils'
import {
    QueryBuilder,
    QueryBuilderSkeleton,
    QueryBuilderValues,
} from './QueryBuilder'
import { primaryIconSize, theme } from '../../lib/styles/universal'
import { InsightsLoadingOverlay } from './InsightsLoadingOverlay'
import { Popover } from '../atoms'

interface SurveyAnswerInsightQuestionHeaderProps {
    question: string
    subcopy: string
}
const SurveyAnswerInsightQuestionHeader: FC<
    SurveyAnswerInsightQuestionHeaderProps
> = ({ question, subcopy }) => (
    <div>
        <Typography variant="h3">Q. {question}</Typography>
        <Typography variant="body2" color={colors.grays.NIMBUS}>
            {subcopy}
        </Typography>
    </div>
)

interface SurveyAnswerInsightResponseTableProps {
    data: SurveyAnswerInsight
    currentAnswer?: SurveyAnswerInsightItem | null
    lastUpdated: Date
    selectedAnswer: number
    setSelectedAnswer(answer: number): void
}
const SurveyAnswerInsightResponseTable: FC<
    SurveyAnswerInsightResponseTableProps
> = (props) => {
    const hasAnswers = props.data ? props.data.answers.length > 0 : false

    const itemType = useMemo(() => {
        if (props.data.question_type === 'TwoD_Grid_Placement') {
            return 'action'
        } else if (props.data.question_type === 'Checkboxes') {
            return 'essential item'
        } else {
            return 'contact'
        }
    }, [props.data.question_type])

    return (
        <TableContainer
            component={Paper}
            sx={{ maxHeight: '30rem', overflowY: 'auto' }}
        >
            <Table stickyHeader>
                <TableHead
                    sx={{
                        backgroundColor: '#E2E2E266',
                    }}
                >
                    <TableRow>
                        <TableCell
                            colSpan={
                                (props.data.answers?.[0]?.bars.length ?? 1) + 2
                            }
                            sx={{
                                textAlign: 'center',
                                verticalAlign: 'middle',
                            }}
                        >
                            Coping Capacity Score
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell
                            rowSpan={2}
                            sx={{
                                height: 'auto',
                                verticalAlign: 'middle',
                            }}
                        >
                            Public Response
                        </TableCell>{' '}
                        {hasAnswers
                            ? props.data.answers[0].bars.map((category) => (
                                  <TableCell
                                      key={category.id}
                                      sx={{
                                          textAlign: 'center',
                                      }}
                                  >
                                      {category.label}
                                  </TableCell>
                              ))
                            : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.data.answers?.map((answerItem, index) => {
                        const total = answerItem.bars.reduce(
                            (acc, curr) => (acc += curr.value),
                            0
                        )
                        return (
                            <TableRow
                                key={`answer-${index + 1}`}
                                onClick={() => {
                                    props.setSelectedAnswer(index)
                                }}
                                sx={{
                                    backgroundColor:
                                        index === props.selectedAnswer
                                            ? colors.softTones.BLUEBERRY
                                            : colors.grays.BLANC,
                                    textAlign: 'center',
                                    position: 'relative',
                                    boxSizing: 'border-box',
                                }}
                            >
                                <TableCell
                                    sx={{
                                        display: 'flex',
                                        height: '100%',
                                        alignItems: 'center',
                                    }}
                                >
                                    {answerItem.answer}
                                    {answerItem.bars.length > 0 &&
                                        total > 0 &&
                                        answerItem.bars[0].value / total <
                                            0.75 && (
                                            <Popover
                                                helpText="This score indicates a significant vulnerability."
                                                icon={
                                                    <IoAlertCircleOutline
                                                        size={primaryIconSize}
                                                    />
                                                }
                                            />
                                        )}
                                    {answerItem.weight > 1 && (
                                        <Popover
                                            helpText={`Indicates a higher priority ${itemType}.`}
                                            icon={
                                                <IoInformationCircleOutline
                                                    size={primaryIconSize}
                                                />
                                            }
                                        />
                                    )}
                                </TableCell>
                                {answerItem.bars.length === 0
                                    ? null
                                    : answerItem.bars.map((category) => (
                                          <TableCell key={category.id}>
                                              {total > 0
                                                  ? `${Math.floor(
                                                        (category.value /
                                                            total) *
                                                            100
                                                    )}%`
                                                  : 'N/A'}
                                          </TableCell>
                                      ))}
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

interface SurveyAnswerInsightViewProps {
    organization: Organization
    queryId?: number
    queryItemSelectorPlaceholder?: string
    selectedQueryItem?: number
    selectedArea: string[]
    areaSelectorPlaceholder?: string
    queryItemTitle: string
    queryItems: PickerItem<number | undefined>[]
    startDate: Date | null
    endDate: Date | null
    onReloadPress(): void
    updatedDate?: Date
    onRunQueryPress(values: QueryBuilderValues): void
    onResetQueryPress: React.MouseEventHandler
    onSaveQueryPress: React.MouseEventHandler
    onDownloadPress: React.MouseEventHandler
    onDetailsPress?: (string: Insight['placement_id']) => void
    selectedLocationString: string
    selectedDatesString: string
    minQueryDate: Date
    insights?: InsightViewProps[]
    data?: SurveyAnswerInsight
    loading?: boolean
    quickStatRowTitle?: string

    onBack: React.MouseEventHandler
    onPrevQuestionPress: React.MouseEventHandler
    onNextQuestionPress: React.MouseEventHandler

    // Map props
    mapDefaultZoom?: number
    mapDefaultCenter?: number[]
    onSwitchMapColorTheme: (value: string) => void
    desktopMenuOpen?: boolean
}

export const SurveyAnswerInsightView: FC<SurveyAnswerInsightViewProps> = (
    props: SurveyAnswerInsightViewProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const [selectedAnswer, setSelectedAnswer] = React.useState<number>(0)
    const lgScreens = useWindowSizeUp('lg')
    const mdScreens = useWindowSizeUp('md')
    const hasData = props?.data || null
    const hasAnswers = hasData ? hasData?.answers?.length > 0 : false
    const currentAnswer = hasAnswers
        ? props.data?.answers[selectedAnswer]
        : null
    const showGraph = props.data?.answers[selectedAnswer].bars.some(
        (bar) => bar.value > 0
    )
    const showMap = currentAnswer && currentAnswer.map_config.length > 0
    const lastUpdated =
        hasData?.answers[selectedAnswer].date_updated || new Date()
    const {
        bars = [],
        axes = { x: '', y: '' },
        orientation = 'vertical',
        ...data
    } = hasAnswers && props.data?.answers[selectedAnswer]
        ? props?.data.answers[selectedAnswer]
        : {}

    React.useEffect(() => {
        if (props.loading) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }

        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [props.loading])

    return (
        <div className={localClasses.container} id={props.data?.placement_id}>
            {props.loading && <InsightsLoadingOverlay />}
            <div className={localClasses.titleAndQueryBuilder}>
                <div className={localClasses.title}>
                    {props.loading ? (
                        <Skeleton
                            width="min(80vw, 25rem)"
                            height={
                                theme.typography[mdScreens ? 'h2' : 'h3']
                                    .lineHeight
                            }
                        />
                    ) : (
                        <Typography
                            variant={mdScreens ? 'h2' : 'h3'}
                            component="h1"
                            display="flex"
                            flexDirection="column"
                        >
                            {props.data?.question ?? null}
                            <Typography
                                variant={mdScreens ? 'h3' : 'h4'}
                                component="span"
                            >
                                {props.organization.name}
                            </Typography>
                        </Typography>
                    )}
                </div>
                <LastUpdatedWithReload
                    date={props.updatedDate}
                    onReload={props.onReloadPress}
                />
                <div style={{ gridColumn: lgScreens ? '1 / span 2' : '' }}>
                    {!props.queryId ? (
                        <QueryBuilderSkeleton />
                    ) : (
                        <QueryBuilder
                            organization={props.organization}
                            pickerOneLabel={props.queryItemTitle}
                            pickerOneValue={props.selectedQueryItem}
                            pickerOnePlaceholder={
                                props.queryItemSelectorPlaceholder
                            }
                            pickerOneOptions={props.queryItems}
                            areaPickerLabel="Area"
                            selectedAreas={props.selectedArea}
                            areaPickerPlaceholder={
                                props.areaSelectorPlaceholder
                            }
                            startDate={props.startDate}
                            endDate={props.endDate}
                            minDate={props.minQueryDate}
                            onRunQueryPress={props.onRunQueryPress}
                            onResetQueryPress={props.onResetQueryPress}
                            onSaveQueryPress={props.onSaveQueryPress}
                            onDownloadPress={props.onDownloadPress}
                        />
                    )}
                </div>
            </div>
            <Link
                component="button"
                onClick={props.onBack}
                variant="body1"
                gap="0.5rem"
            >
                <IoChevronBack size="1rem" />
                Prep Check Overview
            </Link>

            {/* Prep Check question details */}
            {props.data ? (
                <>
                    <SurveyAnswerInsightQuestionHeader {...props.data} />

                    {/* Response data */}
                    <Grid container spacing={2}>
                        {/* Response table */}
                        <Grid item xs={12} lg={showGraph ? 8 : 12}>
                            <SurveyAnswerInsightResponseTable
                                data={props.data}
                                currentAnswer={currentAnswer}
                                lastUpdated={lastUpdated}
                                selectedAnswer={selectedAnswer}
                                setSelectedAnswer={setSelectedAnswer}
                            />
                        </Grid>
                        {showGraph && currentAnswer ? (
                            <Grid
                                item
                                xs={12}
                                lg={4}
                                component={Paper}
                                sx={{ height: '30rem', marginTop: '1rem' }}
                            >
                                <InsightView
                                    placement_id={currentAnswer.placement_id}
                                    options={[
                                        {
                                            icon: <IoAdd />,
                                            label: 'test',
                                            onClick: () => {},
                                        },
                                    ]}
                                    ctas={[]}
                                    type="bar"
                                    headline={'Insights'}
                                    bars={bars}
                                    axes={axes}
                                    orientation={orientation}
                                    date_updated={lastUpdated}
                                    {...data}
                                />
                            </Grid>
                        ) : null}

                        {/* Graph of selected response's value distribution */}

                        {/* Map of selected response's value by area */}
                        {hasAnswers && showMap ? (
                            <Grid item xs={12} xl={12}>
                                <InsightView
                                    placement_id={props.data?.placement_id}
                                    options={[
                                        {
                                            icon: <IoAdd />,
                                            label: 'test',
                                            onClick: () => {},
                                        },
                                    ]}
                                    headline={props.data?.headline} // TODO
                                    ctas={[]}
                                    minZoom={
                                        ZOOM_THRESHOLDS[
                                            props.organization
                                                .default_zoom_level
                                        ]
                                    }
                                    type="map"
                                    legend={
                                        props.data?.answers[selectedAnswer]
                                            ?.map_legend
                                    }
                                    defaultZoom={props.mapDefaultZoom}
                                    defaultCenter={props.mapDefaultCenter}
                                    onSwitchColorTheme={
                                        props.onSwitchMapColorTheme
                                    }
                                    date_updated={lastUpdated}
                                    onUpdate={async () => {}} // TODO
                                    map_config={
                                        props.data?.answers[selectedAnswer]
                                            ?.map_config
                                    }
                                    config={{ pickers: {} }} // TODO
                                    onSwitchVariant={() => {}} // TODO
                                    onSwitchMode={() => {}} //TODO
                                    {...props}
                                />
                            </Grid>
                        ) : null}
                    </Grid>

                    {/* Prev/Next Question CTAs */}
                    <Grid
                        container
                        spacing={2}
                        mt="1rem"
                        justifyContent="space-between"
                    >
                        <Grid
                            item
                            xs={6}
                            className={classNames(
                                localClasses.questionListArrow,
                                localClasses.previousQuestion
                            )}
                        >
                            {props.data.prev ? (
                                <Link
                                    component="button"
                                    onClick={props.onPrevQuestionPress}
                                    gap="0.5rem"
                                    alignSelf="flex-start"
                                    variant="body1"
                                >
                                    <IoChevronBack size="1rem" />
                                    Previous Question
                                </Link>
                            ) : null}
                        </Grid>
                        <Grid
                            item
                            xs={6}
                            className={classNames(
                                localClasses.questionListArrow,
                                localClasses.nextQuestion
                            )}
                        >
                            {props.data.next ? (
                                <Link
                                    component="button"
                                    onClick={props.onNextQuestionPress}
                                    gap="0.5rem"
                                    alignSelf="flex-end"
                                    variant="body1"
                                >
                                    Next Question
                                    <IoChevronForward size="1rem" />
                                </Link>
                            ) : null}
                        </Grid>
                    </Grid>
                </>
            ) : null}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
        padding: '1.5rem',
        position: 'relative',
    },
    questionListArrow: {
        display: 'flex',
    },
    previousQuestion: {
        justifyContent: 'flex-start',
    },
    nextQuestion: {
        justifyContent: 'flex-end',
    },
    header: {
        display: 'flex',
        padding: '1.25rem 0',
        gap: '1rem',
        flexDirection: 'column',
        [theme.breakpoints.up('lg')]: {
            flexDirection: 'row',
        },
    },
    titleAndQueryBuilder: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
        alignItems: 'center',
        flex: 1,
        gap: '0.5rem',
    },
    mdTitleAndQueryBuilder: {
        gridTemplateColumns: '1fr auto',
        gap: '1rem',
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        gridColumn: '1 / span 2',
        [theme.breakpoints.up('lg')]: {
            gridColumn: 'unset',
        },
    },
    queryInfo: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingRight: '1rem',
        gap: '1rem',
    },
    insightGridContainer: {
        padding: '1rem 0',
        margin: '0 1rem',
        borderTop: `1px ${colors.grays.NOIR} solid`,
        flex: 1,
    },
    queriedStrings: {
        padding: '0 0 1rem',
    },
    queryCtas: {
        height: '3rem',
    },
})
