import React, {
    CSSProperties,
    FC,
    ReactNode,
    useEffect,
    useRef,
    useState,
} from 'react'
import { getDeviceType } from '../../lib/utils'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import classNames from 'classnames'
import { IoChevronBack, IoChevronForward } from 'react-icons/io5'
import { colors } from '@hazadapt-git/public-core-base'
import { useStyles } from '../../lib/styles/universal'

interface HorizontalScrollerProps {
    children: ReactNode
    styles?: CSSProperties
    gap?: string | number
}

export const HorizontalScroller: FC<HorizontalScrollerProps> = ({
    children,
    styles,
    gap = '1rem',
}) => {
    const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false)
    const [canScrollRight, setCanScrollRight] = useState<boolean>(false)
    const [showScrollButtons, setShowScrollButtons] = useState<boolean>(false)
    const scrollRef = useRef<HTMLDivElement>(null)
    const deviceType = getDeviceType()
    const { classes: localClasses } = useLocalStyles()
    const { classes } = useStyles()

    useEffect(() => {
        const handleScroll = () => {
            if (scrollRef.current) {
                const { scrollLeft, scrollWidth, clientWidth } =
                    scrollRef.current
                setCanScrollLeft(scrollLeft > 0)
                setCanScrollRight(scrollLeft + clientWidth < scrollWidth - 1)
            }
        }

        const scrollRefCurrent = scrollRef.current

        if (scrollRefCurrent) {
            handleScroll()
            scrollRefCurrent.addEventListener('scroll', handleScroll)
        }

        return () => {
            if (scrollRefCurrent) {
                scrollRefCurrent.removeEventListener('scroll', handleScroll)
            }
        }
    }, [children])

    useEffect(() => {
        if (deviceType === 'desktop' && (canScrollLeft || canScrollRight)) {
            setShowScrollButtons(true)
        } else {
            setShowScrollButtons(false)
        }
    }, [canScrollLeft, canScrollRight, deviceType])

    const scroll = (direction: 'left' | 'right') => {
        if (scrollRef.current) {
            if (direction === 'left') {
                scrollRef.current.scrollBy({ left: -200, behavior: 'smooth' })
            } else {
                scrollRef.current.scrollBy({ left: 200, behavior: 'smooth' })
            }
        }
    }

    return (
        <div className={localClasses.wrapper}>
            {showScrollButtons && (
                <Button
                    onClick={() => scroll('left')}
                    className={classNames(
                        classes.unstyledButton,
                        localClasses.scrollButton,
                        localClasses.leftButton
                    )}
                    disabled={!canScrollLeft}
                >
                    <IoChevronBack
                        color={colors.grays.BLANC}
                        size={'1.75rem'}
                        aria-label="Scroll content left"
                    />
                </Button>
            )}
            <Box
                className={classNames(localClasses.content, {
                    [localClasses.scrollable]: showScrollButtons,
                })}
                sx={{ gap: { gap }, ...styles }}
                ref={scrollRef}
            >
                {children}
            </Box>
            {showScrollButtons && (
                <Button
                    onClick={() => scroll('right')}
                    className={classNames(
                        classes.unstyledButton,
                        localClasses.scrollButton,
                        localClasses.rightButton
                    )}
                    disabled={!canScrollRight}
                >
                    <IoChevronForward
                        color={colors.grays.BLANC}
                        size={'1.75rem'}
                        aria-label="Scroll content right"
                    />
                </Button>
            )}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    wrapper: {
        alignItems: 'center',
        display: 'flex',
        height: 'auto',
        overflow: 'hidden',
        position: 'relative',
        width: '100%',
    },
    content: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'flex-start',
        overflowX: 'auto',
        position: 'relative',
        scrollbarWidth: 'none',
    },
    scrollable: { paddingLeft: '1.75rem', paddingRight: '1.75rem' },
    scrollButton: {
        alignItems: 'center',
        backgroundColor: colors.primary.CERULEAN,
        display: 'flex',
        height: '100%',
        maxHeight: 'min(100%, 3rem)',
        padding: '0',
        position: 'absolute',
        width: '1.5rem',
        zIndex: 1,
    },
    leftButton: {
        borderBottomRightRadius: '.5rem',
        borderTopRightRadius: '.5rem',
        left: '0',
    },
    rightButton: {
        borderBottomLeftRadius: '.5rem',
        borderTopLeftRadius: '.5rem',
        right: '0',
    },
})
