import React, { FC, useCallback, useEffect, useState } from 'react'
import { InsightViewProps } from './InsightView'
import { insightHasData, useResponsiveStyles } from '../../lib/utils'
import Button from '@mui/material/Button'
import { useStyles } from '../../lib/styles/universal'
import { makeStyles } from 'tss-react/mui'
import { colors } from '@hazadapt-git/public-core-base'
import {
    IoChevronBack,
    IoChevronForward,
    IoInformationCircle,
} from 'react-icons/io5'
import { BarGraphInsightView } from './BarGraphInsightView'
import { LineGraphInsightView } from './LineGraphInsightView'
import { DonutChartInsightView } from './DonutChartInsightView'
import { LineFillInsightView } from './LineFillInsightView'
import { MultiCounterInsightView } from './MultiCounterInsightView'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { Popover } from '../atoms'
import { useLocation } from 'react-router'

interface FullScreenInsightViewProps {
    currentInsightIndex: number
    insights: InsightViewProps[]
    onClose(): void
}

export const FullScreenInsightView: FC<FullScreenInsightViewProps> = ({
    currentInsightIndex,
    insights,
    onClose,
}) => {
    const [currentIndex, setCurrentIndex] =
        useState<number>(currentInsightIndex)
    const { classes } = useStyles()
    const { classes: localClasses } = useLocalStyles()
    const location = useLocation()
    const responsiveStyles = useResponsiveStyles()

    const insight = insights[currentIndex]

    const insightsWithData = insights.filter((i) => insightHasData(i))

    const handleNext = useCallback(() => {
        setCurrentIndex((prev) => (prev + 1) % insightsWithData.length)
    }, [insightsWithData.length])

    const handlePrevious = useCallback(() => {
        setCurrentIndex(
            (prev) =>
                (prev - 1 + insightsWithData.length) % insightsWithData.length
        )
    }, [insightsWithData.length])

    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            switch (event.key) {
                case 'Escape':
                    onClose()
                    break
                case 'ArrowRight':
                    handleNext()
                    break
                case 'ArrowLeft':
                    handlePrevious()
                    break
                default:
                    break
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [onClose, handleNext, handlePrevious])

    const renderInsight = (insight: InsightViewProps) => {
        switch (insight.type) {
            case 'bar':
                return <BarGraphInsightView {...insight} showAllData />
            case 'line':
                return <LineGraphInsightView {...insight} />
            case 'donut':
                return <DonutChartInsightView {...insight} />
            case 'line-fill':
                return <LineFillInsightView {...insight} />
            case 'multi-counter':
                return <MultiCounterInsightView {...insight} />
            default:
                return null
        }
    }

    const getLocation = () => {
        if (location.pathname.includes('home')) return 'Home Page'
        if (location.pathname.includes('hazard'))
            return 'Hazard Guide Activity Overview'
        if (location.pathname.includes('prep'))
            return 'Prep Check Activity Overview'
    }

    return (
        <Box className={localClasses.container} style={responsiveStyles}>
            {/* TITLE, EXPLAINER, SUBLINE */}
            <Box className={localClasses.title}>
                <Typography variant="h3" className={localClasses.title}>
                    {insight.headline}
                </Typography>
                {insight.explainer ? (
                    <Box display="flex" alignItems="center">
                        <Popover
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            icon={
                                <IoInformationCircle
                                    color={colors.grays.NIMBUS}
                                />
                            }
                            helpText={insight.explainer}
                        />
                    </Box>
                ) : null}
            </Box>
            <Typography
                sx={{
                    minHeight: '1.5rem',
                    visibility: insight.subline ? 'visible' : 'hidden',
                }}
            >
                {insight.subline || ''}
            </Typography>
            {/* HEADER ACTIONS */}
            <Box className={localClasses.headerActions}>
                <Typography
                    component="button"
                    className={localClasses.backButton}
                    onClick={onClose}
                >
                    <IoChevronBack style={{ paddingTop: '.1rem' }} />
                    {`Return to ${getLocation()}`}
                </Typography>
                <Box className={classes.buttonGroup}>
                    <Typography fontWeight={500}>Export As: </Typography>
                    {insight.options.map((o) => (
                        <Button
                            key={o.label}
                            onClick={(e) => o.onClick?.(e)}
                            endIcon={o.icon}
                        >
                            {o.label.replace('Download as ', '')}
                        </Button>
                    ))}
                </Box>
            </Box>
            {/* INSIGHT */}
            <Grid container flexGrow={1} overflow={'hidden'} padding={'.5rem'}>
                <Grid
                    item
                    xs={2.5}
                    display={'flex'}
                    alignItems="center"
                    justifyContent="flex-start"
                >
                    <Button
                        onClick={handlePrevious}
                        startIcon={<IoChevronBack />}
                    >
                        Previous Graph
                    </Button>
                </Grid>
                <Grid item xs={7} className={localClasses.insightContainer}>
                    <Box className={localClasses.graphWrapper}>
                        {renderInsight(insight)}
                    </Box>
                </Grid>
                <Grid
                    item
                    xs={2.5}
                    display={'flex'}
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Button onClick={handleNext} endIcon={<IoChevronForward />}>
                        Next Graph
                    </Button>
                </Grid>
            </Grid>
        </Box>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        backgroundColor: colors.grays.BLANC,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        left: 0,
        overflow: 'hidden',
        padding: '1rem',
        position: 'fixed',
        top: 0,
        zIndex: 2,
    },
    title: {
        alignItems: 'center',
        display: 'flex',
        gap: '0.5rem',
        minHeight: '3rem',
    },
    headerActions: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '1rem',
    },
    backButton: {
        alignItems: 'center',
        all: 'unset',
        color: colors.primary.CERULEAN,
        cursor: 'pointer',
        display: 'flex',
        fontWeight: '500',
        gap: '0.25rem',
        textDecoration: 'underline',
        '&:hover': {
            textDecorationThickness: '2px',
        },
        '&:focus': {
            outline: '2px solid',
            outlineColor: colors.primary.CERULEAN,
        },
    },
    insightContainer: {
        alignItems: 'center',
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        padding: '1rem',
    },
    graphWrapper: {
        alignItems: 'center',
        aspectRatio: '1 / 1',
        borderRadius: '0.5rem',
        boxShadow: '0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.35)',
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'center',
        maxHeight: '100%',
        maxWidth: '100%',
        padding: '1rem',
    },
})
