import React, { FC, useCallback, useMemo } from 'react'
import Select, {
    components,
    DropdownIndicatorProps,
    MultiValue,
    MultiValueProps,
    OptionProps,
    SingleValue,
    StylesConfig,
} from 'react-select'
import {
    CellMeasurer,
    CellMeasurerCache,
    List,
    ListRowRenderer,
} from 'react-virtualized'
import {
    applyColorToPickerSubcomponents,
    primaryIconSize,
} from '../../lib/styles/universal'
import {
    colors,
    GroupedPickerOptions,
    PickerItem,
} from '@hazadapt-git/public-core-base'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'
import { IoCheckmark, IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { makeStyles } from 'tss-react/mui'
import classNames from 'classnames'

export interface PickerProps {
    data: PickerItem<any>[] | GroupedPickerOptions<any>[]
    selectColor?: string
    selectIcon?: React.ElementType
    fullWidth?: boolean
    inline?: boolean
    inputLabel?: string
    disabled?: boolean
    variableSize?: boolean
    bold?: boolean
    id: string
    dark?: boolean
    variant?: 'filled' | 'outlined'
    placeholder?: string
    showSearch?: boolean
    value?: any
    label?: string
    virtualized?: boolean
    onChange?: (value: any) => void
    smallLabel?: boolean
    detachedLabel?: boolean
    multi?: boolean
    onlySelectOneGroup?: boolean
}

type MenuListProps = {
    children: React.ReactNode
}

const MenuList: React.FC<MenuListProps> = ({ children }) => {
    const rows = React.Children.toArray(children)

    // cache for measuring row heights
    const cache = new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 34,
        minHeight: 34,
    })

    const rowRenderer: ListRowRenderer = ({
        key,
        index,
        style,
        parent,
    }: {
        key: string
        index: number
        style: React.CSSProperties
        parent: any
    }) => (
        // dynamically set row heights based on contents
        <CellMeasurer
            cache={cache}
            columnIndex={0}
            key={key}
            parent={parent}
            rowIndex={index}
        >
            {({ measure }) => (
                <div key={key} style={style} onLoad={measure}>
                    {rows[index]}
                </div>
            )}
        </CellMeasurer>
    )
    return (
        <List
            style={{ width: '100%' }}
            width={900}
            height={300}
            rowHeight={cache.rowHeight}
            rowCount={rows.length}
            rowRenderer={rowRenderer}
            deferredMeasurementCache={cache}
            aria-live="polite"
        />
    )
}

const DropdownIndicator = (props: DropdownIndicatorProps<PickerItem<any>>) => (
    <components.DropdownIndicator {...props}>
        {props.isFocused ? <IoChevronUp /> : <IoChevronDown />}
    </components.DropdownIndicator>
)

const CustomOption: FC<OptionProps<PickerItem<any>>> = (props) => {
    const { classes: localClasses } = useLocalStyles()
    const pickerItem = props.data as PickerItem<any>
    const hasImages = props.options.some(
        (option) => (option as PickerItem<any>).imageSrc
    )

    return (
        <components.Option
            {...props}
            className={classNames(localClasses.option, {
                [localClasses.selectedOption]: props.isSelected,
            })}
        >
            {pickerItem.imageSrc ? (
                <img
                    src={props.data.imageSrc}
                    alt={props.data.label}
                    className={localClasses.orgImage}
                />
            ) : (
                hasImages && <div className={localClasses.inset} />
            )}
            <div className={localClasses.optionText}>{props.children}</div>
            {props.isSelected ? (
                <IoCheckmark
                    size={primaryIconSize}
                    color={colors.grays.BLANC}
                />
            ) : null}
        </components.Option>
    )
}

const MultiValueElem: FC<
    MultiValueProps<PickerItem<any>> & { count: number }
> = (props: MultiValueProps<PickerItem<any>> & { count: number }) => {
    if (props.index > 0) return null
    return (
        <components.MultiValue {...props}>
            <Typography aria-hidden>
                {props.count > 1 ? `${props.count} selected` : props.data.value}
            </Typography>
            {/* more verbose desc for screen readers */}
            <Typography display={'none'}>
                {props.count > 1
                    ? `${props.count} items selected`
                    : `Selected ${props.data.value}`}
            </Typography>
        </components.MultiValue>
    )
}

export const Picker: FC<PickerProps> = (props: PickerProps) => {
    const {
        inputLabel,
        data,
        disabled,
        selectColor,
        selectIcon,
        fullWidth,
        inline,
        variableSize,
        bold,
        id,
        dark,
        value,
        variant = 'outlined',
        placeholder,
        showSearch,
        onChange,
        multi,
        label,
        smallLabel,
        detachedLabel,
        onlySelectOneGroup = false,
        virtualized = data.length > 0 && 'options' in data[0]
            ? (data as GroupedPickerOptions<any>[]).flatMap((d) => d.options)
                  .length > 20
            : data.length > 20,
        ...selectProps
    } = props
    const { classes: localClasses } = useLocalStyles()

    const customStyles: StylesConfig<PickerItem<any>> = {
        container: () => ({
            border: 'none',
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: dark
                ? colors.grays.BLANC
                : provided.backgroundColor,
            borderRadius: '0.5rem',
            fontWeight: bold ? 500 : 'normal',
            height: '3rem',
            padding: `0 calc(0.5rem - ${state.isFocused ? 1 : 0}px)`,
            borderColor: state.isFocused
                ? colors.primary.CERULEAN
                : `rgba(0, 0, 0, 0.23)`, // To match MUI
            borderWidth: state.isFocused ? 2 : 1,
            boxShadow: 'none',
            boxSizing: 'border-box',
            transition: 'none',
            '&:hover': {
                borderColor: colors.primary.CERULEAN,
            },
        }),
        valueContainer: (provided) => ({
            ...provided,
            flexWrap: 'nowrap',
            padding: '0.125rem 0 0.125rem 0.25rem',
        }),
        input: (provided) => ({
            ...provided,
            minWidth: '1rem',
        }),
        menu: (provided) => ({
            ...provided,
            color: dark ? colors.grays.BLANC : 'initial',
            borderRadius: '0.5rem',
            zIndex: 10,
            overflow: 'hidden',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: dark ? colors.grays.BLANC : provided.color,
            textOverflow: 'ellipsis',
            textWrap: 'nowrap',
        }),
        menuList: (provided) => ({
            ...provided,
            padding: 0,
            margin: 0,
            overflow: 'auto',
        }),
        multiValueLabel: (base) => ({
            ...base,
            backgroundColor: colors.grays.BLANC,
            paddingLeft: 0,
            fontSize: 'inherit',
        }),
        option: (provided, params) => ({
            ...provided,
            color:
                params.data.value && !params.isDisabled
                    ? colors.grays.NOIR
                    : colors.grays.NIMBUS,
        }),
        menuPortal: (provided) => ({ ...provided, zIndex: 1300 }),
    }

    const handleChange = useCallback(
        (
            selectedOption:
                | SingleValue<PickerItem<any>>
                | MultiValue<PickerItem<any>>
        ) => {
            if (Array.isArray(selectedOption)) {
                onChange?.(
                    ((selectedOption ?? []) as MultiValue<PickerItem<any>>).map(
                        (v) => v.value
                    )
                )
            } else {
                onChange?.(
                    (selectedOption as SingleValue<PickerItem<any>>)?.value ??
                        null
                )
            }
        },
        [onChange]
    )

    const pickerValue = useMemo(() => {
        if (data.length === 0) return [] as PickerItem<any>[]
        const selected = Array.isArray(value) ? value : [value]
        if ('options' in data[0]) {
            return (data as GroupedPickerOptions<any>[])
                .flatMap((d) => d.options)
                .filter((o) => selected.includes(o.value))
        } else {
            return (data as PickerItem<any>[]).filter((d) =>
                selected.includes(d.value)
            )
        }
    }, [data, value])

    const selectedGroup = useMemo(() => {
        if (data.length === 0 || !('options' in data[0])) return undefined
        for (const group of data as GroupedPickerOptions<any>[]) {
            const groupValues = group.options.map((o) => o.value)
            if (pickerValue.some((pv) => groupValues.includes(pv.value))) {
                return group.id
            }
        }
    }, [data, pickerValue])

    const pickerData: PickerItem<any>[] = useMemo(() => {
        if (data.length === 0 || !('options' in data[0]))
            return data as PickerItem<any>[]
        const values: PickerItem<any>[] = []
        for (const group of data as GroupedPickerOptions<any>[]) {
            values.push({
                label: `${group.label} (${group.options.length})`,
                value: group.id,
                disabled: true,
            })
            values.push(
                ...group.options.map((o) => ({
                    ...o,
                    disabled:
                        onlySelectOneGroup &&
                        !!selectedGroup &&
                        group.id !== selectedGroup,
                }))
            )
        }
        return values
    }, [data, onlySelectOneGroup, selectedGroup])

    return (
        <FormControl
            fullWidth={fullWidth}
            variant={variant}
            disabled={disabled}
            sx={{
                ...(fullWidth
                    ? undefined
                    : {
                          width: variableSize ? 'fit-content' : '100%',
                          minWidth: 'unset',
                          maxWidth: variableSize ? undefined : '25rem',
                          height: 'auto',
                      }),
                ...applyColorToPickerSubcomponents(selectColor, variant),
                // ...props.sx,
            }}
        >
            {props.inputLabel && (
                <>
                    {props.detachedLabel ? (
                        <Typography
                            variant={props.smallLabel ? 'h5' : 'h4'}
                            pb="0.625rem"
                            color={props.selectColor}
                            fontStyle="normal"
                            fontWeight={500}
                        >
                            {`${props.inputLabel}:`}
                        </Typography>
                    ) : (
                        <Typography
                            variant="body2"
                            color={props.selectColor}
                            className={localClasses.nestedLabel}
                        >
                            {`${props.inputLabel}:`}
                        </Typography>
                    )}
                </>
            )}
            <Select
                {...selectProps}
                aria-label={`Select ${props.label || props.inputLabel}`}
                components={{
                    ...(virtualized ? { MenuList } : {}),
                    IndicatorSeparator: null,
                    DropdownIndicator,
                    Option: CustomOption,
                    ClearIndicator: () => null,
                    MultiValue: (params) => (
                        <MultiValueElem
                            {...params}
                            count={pickerValue.length}
                        />
                    ),
                    MultiValueRemove: () => null,
                }}
                id={id}
                styles={customStyles}
                options={pickerData}
                isDisabled={disabled}
                isSearchable
                placeholder={placeholder}
                isMulti={multi}
                isOptionDisabled={(option) => !!option.disabled}
                menuPortalTarget={document.body}
                onChange={(
                    val:
                        | SingleValue<PickerItem<any>>
                        | MultiValue<PickerItem<any>>
                ) => handleChange(val)}
                hideSelectedOptions={false}
                value={multi ? pickerValue : pickerValue[0]}
            />
        </FormControl>
    )
}

const useLocalStyles = makeStyles()({
    orgImage: {
        width: '1.25rem',
        height: '1.25rem',
        objectFit: 'contain',
        marginRight: '.5rem',
        verticalAlign: 'top',
    },
    inset: {
        display: 'inline-block',
        width: '1.25rem',
        height: '1.25rem',
        marginRight: '.5rem',
    },
    nestedLabel: {
        backgroundColor: '#fff',
        fontSize: '.75rem',
        fontWeight: '600',
        left: '.6rem',
        padding: '0 .25rem',
        position: 'absolute',
        top: '-.55rem',
        zIndex: 1,
    },
    option: {
        display: 'flex',
        alignItems: 'center',
    },
    selectedOption: {
        color: colors.grays.BLANC,
    },
    optionText: {
        flex: 1,
    },
})
