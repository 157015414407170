import React, { FC } from 'react'
import { InsightsPageProps, SurveyAnswerInsightView } from '../organisms'
import { SurveyAnswerInsight } from '@hazadapt-git/public-core-base'

interface SurveyAnswerInsightTemplateProps
    extends Omit<
        InsightsPageProps,
        'pageTitle' | 'queryItemTitle' | 'selectedQueryString'
    > {
    onBack: React.MouseEventHandler
    onNextQuestionPress(): void
    onPrevQuestionPress(): void
    surveyData?: SurveyAnswerInsight
    desktopMenuOpen?: boolean
}

export const SurveyAnswerInsightTemplate: FC<
    SurveyAnswerInsightTemplateProps
> = (props: SurveyAnswerInsightTemplateProps) => {
    return (
        <SurveyAnswerInsightView
            organization={props.organization}
            queryItemTitle="Prep Check"
            queryId={props.queryId}
            selectedQueryItem={props.selectedQueryItem}
            queryItems={props.queryItems}
            queryItemSelectorPlaceholder="All Prep Checks"
            selectedArea={props.selectedAreas}
            areaSelectorPlaceholder="Full Jurisdiction"
            startDate={props.startDate}
            endDate={props.endDate}
            onReloadPress={() => props.onReloadPress}
            updatedDate={props.updatedDate}
            onRunQueryPress={props.onRunQueryPress}
            onResetQueryPress={props.onResetQueryPress}
            onSaveQueryPress={props.onSaveQueryPress}
            onDownloadPress={props.onDownloadPress}
            selectedLocationString={props.selectedLocationString}
            selectedDatesString={props.selectedDatesString}
            insights={props.insights}
            quickStatRowTitle={props.quickStatRowTitle}
            loading={props.loading}
            minQueryDate={props.minQueryDate}
            desktopMenuOpen={props.desktopMenuOpen}
            data={props.surveyData}
            onBack={props.onBack}
            onPrevQuestionPress={props.onPrevQuestionPress}
            onNextQuestionPress={props.onNextQuestionPress}
            mapDefaultZoom={1}
            mapDefaultCenter={[]}
            onSwitchMapColorTheme={() => {}}
        />
    )
}
