import classNames from 'classnames'
import React, { FC } from 'react'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import Skeleton from '@mui/material/Skeleton'
import Avatar from '@mui/material/Avatar'
import {
    ExportFormat,
    InsightQueryDTO,
    colors,
} from '@hazadapt-git/public-core-base'
import { InsightQueryView } from '../molecules'
import { format } from 'date-fns-tz'
import {
    buildQueryAreaName,
    buildQueryDateRange,
    buildQueryHref,
    useWindowSizeUp,
} from '../../lib/utils'

import NoSavedQueriesImage from '../../assets/images/RP_zen_dude_no_text.svg'
import { Box, Container } from '@mui/system'
import { makeStyles } from 'tss-react/mui'
interface InsightQueryTableProps {
    queries?: InsightQueryDTO[]
    onDownloadReportPress(id: number, format?: ExportFormat): void
}

export const InsightQueryTable: FC<InsightQueryTableProps> = (
    props: InsightQueryTableProps
) => {
    const { classes: localClasses } = useLocalStyles()
    const lgScreens = useWindowSizeUp('lg')
    const columns = lgScreens
        ? ['User', 'Query Description', 'Date Last Used', 'Actions']
        : ['User', 'Query Description']
    const noSavedQueriesHeader = 'Nothing to display...yet.'

    return (
        <>
            <TableContainer component={Paper}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((col) => (
                                <TableCell
                                    key={col.toLowerCase().replaceAll(' ', '-')}
                                    className={classNames({
                                        [localClasses.centeredCell]:
                                            col === 'Actions' && !lgScreens,
                                    })}
                                >
                                    {col}
                                </TableCell>
                            ))}
                            {!lgScreens && (
                                <TableCell>
                                    {/* Leave this empty for layout purposes, label is for screen readers */}
                                    <Typography display={'none'}>
                                        Actions
                                    </Typography>
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!props.queries ? (
                            <InsightQuerySkeleton />
                        ) : (
                            props.queries?.map((query, index) => (
                                <InsightQueryView
                                    key={`query-${index + 1}`}
                                    id={query.id}
                                    name={query.user_name}
                                    // email={query.user_email}
                                    profilePictureURI={
                                        query.user_profile_picture_uri
                                    }
                                    description={query.description}
                                    area={buildQueryAreaName(query)}
                                    dateRange={buildQueryDateRange(query)}
                                    timestamp={format(
                                        new Date(query.date_last_used),
                                        `MMMM d, y'\n'h:mm a`
                                    )}
                                    openQueryHref={buildQueryHref(query)}
                                    onDownloadReportPress={
                                        props.onDownloadReportPress
                                    }
                                />
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {props.queries?.length === 0 && (
                <Container fixed>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                    >
                        <Typography
                            color={colors.grays.CHARCOAL}
                            paddingY="2rem"
                            textAlign="center"
                            variant="h1"
                        >
                            {noSavedQueriesHeader}
                        </Typography>
                        <img
                            src={NoSavedQueriesImage}
                            alt="Emergency manager meditating above his desk"
                            style={{
                                width: '100%',
                                maxHeight: 'calc(100vh - 11.5rem)',
                            }}
                        />
                    </Box>
                </Container>
            )}
        </>
    )
}

const InsightQuerySkeleton = () => {
    const lgScreens = useWindowSizeUp('lg')

    return (
        <>
            {Array.from({ length: 10 }).map((_, index) => {
                // creates a random value and utilizes the quadratic fn to skew towards the extremes for better variation
                const randomNameWidth =
                    Math.floor(Math.pow(Math.random(), 2) * (100 - 30 + 1)) + 30
                const randomHazardWidth =
                    Math.floor(Math.pow(Math.random(), 2) * (70 - 30 + 1)) + 30

                return (
                    <TableRow
                        key={index}
                        sx={{ '&.MuiTableRow-root': { height: '5.1rem' } }}
                    >
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                {lgScreens && (
                                    <Box>
                                        <Skeleton variant="circular">
                                            <Avatar />
                                        </Skeleton>
                                    </Box>
                                )}
                                <Box sx={{ width: '100%' }}>
                                    <Skeleton width={`${randomNameWidth}%`} />
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Skeleton width={`${randomHazardWidth}%`} />
                            <Skeleton width="75%" />
                        </TableCell>
                        <TableCell>
                            <Skeleton width="65%" />
                            <Skeleton width="40%" />
                        </TableCell>
                        <TableCell>
                            <Skeleton width="75%" />
                        </TableCell>
                    </TableRow>
                )
            })}
        </>
    )
}

const useLocalStyles = makeStyles()({
    centeredCell: {
        textAlign: 'center',
    },
})
