import React, { FC, useEffect, useState } from 'react'
import { useWindowSizeUp } from '../../lib/utils'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { colors } from '@hazadapt-git/public-core-base'
import { IoChevronDown, IoChevronUp } from 'react-icons/io5'
import { primaryIconSize } from '../../lib/styles/universal'
import { SimplifiedSeatSubscription } from '../../lib/hooks/useBilling'

interface SeatSubscriptionViewProps {
    seatSub: SimplifiedSeatSubscription
    onNavigateToCancelSeats(sub: SimplifiedSeatSubscription): void
}

export const SeatSubscriptionView: FC<SeatSubscriptionViewProps> = ({
    seatSub,
    onNavigateToCancelSeats,
}) => {
    const lgScreen = useWindowSizeUp('lg')
    const [expanded, setExpanded] = useState<boolean>(false)

    useEffect(() => {
        setExpanded(false)
    }, [lgScreen])

    const Chevron = expanded ? IoChevronUp : IoChevronDown

    return (
        <>
            <TableRow
                sx={{
                    backgroundColor:
                        seatSub.status === 'active'
                            ? colors.grays.BLANC
                            : colors.grays.SILK,
                    ...(lgScreen
                        ? {}
                        : {
                              '& td': {
                                  borderBottom: 'unset',
                              },
                          }),
                }}
            >
                {/* Purchase date */}
                {lgScreen && (
                    <TableCell>
                        <Typography textAlign={'center'}>
                            {seatSub.purchaseDate}
                        </Typography>
                    </TableCell>
                )}

                {/* Seat count */}
                <TableCell>
                    <Typography textAlign={'center'}>
                        {seatSub.seatCount}
                    </Typography>
                </TableCell>

                {/* Renewal date */}
                <TableCell>
                    <Typography textAlign={'center'}>
                        {seatSub.renewalDate}
                    </Typography>
                </TableCell>

                {/* Actions */}
                <TableCell
                    sx={{
                        display: 'flex',
                        justifyContent: 'end',
                    }}
                >
                    {lgScreen ? (
                        <>
                            {seatSub.status === 'active' ? (
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() =>
                                        onNavigateToCancelSeats(seatSub)
                                    }
                                >
                                    Cancel Subscription
                                </Button>
                            ) : (
                                <Typography>
                                    <Typography
                                        fontWeight={500}
                                        component="span"
                                    >
                                        Cancelled on:{' '}
                                    </Typography>
                                    {seatSub.cancelDate}
                                </Typography>
                            )}
                        </>
                    ) : (
                        <IconButton onClick={() => setExpanded((ex) => !ex)}>
                            <Chevron
                                size={primaryIconSize}
                                color={colors.primary.CERULEAN}
                            />
                        </IconButton>
                    )}
                </TableCell>
            </TableRow>
            {!lgScreen && (
                <TableRow
                    sx={{
                        backgroundColor:
                            seatSub.status === 'active'
                                ? colors.grays.BLANC
                                : colors.grays.SILK,
                    }}
                >
                    <TableCell sx={{ py: 0 }} colSpan={12}>
                        <Collapse in={expanded} timeout="auto" unmountOnExit>
                            <Stack my="1rem" gap="1rem" alignItems="flex-start">
                                <Typography>
                                    <Typography
                                        fontWeight={500}
                                        component="span"
                                    >
                                        Purchase date:{' '}
                                    </Typography>
                                    {seatSub.purchaseDate}
                                </Typography>
                                {seatSub.status === 'active' ? (
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() =>
                                            onNavigateToCancelSeats(seatSub)
                                        }
                                    >
                                        Cancel Subscription
                                    </Button>
                                ) : (
                                    <Typography>
                                        <Typography
                                            fontWeight={500}
                                            component="span"
                                        >
                                            Cancelled on:{' '}
                                        </Typography>
                                        {seatSub.cancelDate}
                                    </Typography>
                                )}
                            </Stack>
                        </Collapse>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}
