import React, { FC, useCallback } from 'react'
import {
    SingleBarGraphInsight,
    MultiBarGraphInsight,
} from '@hazadapt-git/public-core-base'
import { Bar } from 'react-chartjs-2'
import { ChartData } from 'chart.js/dist/types/index'
import { truncateChartLabel } from '../../lib/utils'
import {
    defaultMultiBarLimit,
    defaultSingleBarLimit,
} from '../../lib/styles/universal'

interface SingleBarGraphInsightViewProps
    extends Omit<SingleBarGraphInsight, 'headline'> {
    headline: React.ReactNode
    showAllData?: boolean
}

interface MultiBarGraphInsightViewProps
    extends Omit<MultiBarGraphInsight, 'headline'> {
    headline: React.ReactNode
    showAllData?: boolean
}
export const BarGraphInsightView: FC<
    SingleBarGraphInsightViewProps | MultiBarGraphInsightViewProps
> = (props: SingleBarGraphInsightViewProps | MultiBarGraphInsightViewProps) => {
    const getSingleBarGraphItems = useCallback(
        (
            data: SingleBarGraphInsightViewProps
        ): ChartData<'bar', number[], string> => {
            const bars = props.showAllData
                ? data.bars
                : data.bars.slice(0, defaultSingleBarLimit)

            const labels = bars.map((b) => b.label)
            const dataset = {
                data: bars.map((b) => b.value),
                backgroundColor: bars.map((b) => b.color),
                borderColor: bars.map((b) => b.color),
                borderRadius: 6,
            }
            return { labels, datasets: [dataset] }
        },
        [props.showAllData]
    )

    const getMultiBarGraphGroups = useCallback(
        (
            data: MultiBarGraphInsightViewProps
        ): ChartData<'bar', number[], string> => {
            return {
                labels: props.showAllData
                    ? data.labels
                    : data.labels.slice(0, defaultMultiBarLimit),
                datasets: data.groups.map((group) => ({
                    label: group.name,
                    data: props.showAllData
                        ? group.values
                        : group.values.slice(0, defaultMultiBarLimit),
                    backgroundColor: group.color,
                    borderColor: group.color,
                    borderRadius: 6,
                })),
            }
        },
        [props.showAllData]
    )

    const data = React.useMemo(
        () =>
            'bars' in props
                ? getSingleBarGraphItems(props)
                : getMultiBarGraphGroups(props),
        [props, getMultiBarGraphGroups, getSingleBarGraphItems]
    )

    return (
        <Bar
            data={data}
            options={{
                aspectRatio: 1,
                indexAxis: props.orientation === 'vertical' ? 'x' : 'y',
                plugins: {
                    legend: {
                        display: 'groups' in props,
                    },
                },
                resizeDelay: 50,
                scales: {
                    x: {
                        stacked: 'groups' in props && props.stacked,
                        title: {
                            display: true,
                            text: props.axes.x,
                        },
                    },
                    y: {
                        stacked: 'groups' in props && props.stacked,
                        title: {
                            display: true,
                            text: props.axes.y,
                        },
                        ticks: {
                            precision: 0,
                            callback: function (value) {
                                return truncateChartLabel(
                                    value as number,
                                    10,
                                    this.getLabelForValue.bind(this)
                                )
                            },
                        },
                        beginAtZero: true,
                    },
                },
                // maintainAspectRatio: false,
            }}
        />
    )
}
