import { Jurisdiction, MapAreaLevel } from '@hazadapt-git/public-core-base'
import bboxPolygon from '@turf/bbox-polygon'
import difference from '@turf/difference'
import { Feature, MultiPolygon, Polygon } from 'geojson'
import * as turf from '@turf/helpers'

export const isPolygonTypeFeature = (feature: Feature): boolean =>
    feature.geometry.type === 'Polygon' ||
    feature.geometry.type === 'MultiPolygon'

export const interactiveMapLayers = Object.values(MapAreaLevel).map(
    (l) => `${l}-fill`
)

export const createJurisdictionMask = (
    jurisdiction?: Jurisdiction
): Feature<Polygon | MultiPolygon> | null => {
    if (!jurisdiction) return null
    const bboxPoly = bboxPolygon([-180, -90, 180, 90])
    const diff = difference(
        turf.featureCollection([
            bboxPoly,
            ...(jurisdiction.features.filter((f) =>
                isPolygonTypeFeature(f)
            ) as Feature<Polygon | MultiPolygon>[]),
        ])
    )
    return diff
}
