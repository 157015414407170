import React, { FC, useCallback, useEffect, useMemo } from 'react'
import { DatePicker, Dialog, Picker } from '../atoms'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import {
    colors,
    PickerItem,
    Organization,
} from '@hazadapt-git/public-core-base'
import { MenuItemProps } from '../../lib/entities'
import { primaryIconSize, theme } from '../../lib/styles/universal'
import {
    IoArrowUndo,
    IoChevronDown,
    IoDownload,
    IoEllipsisVertical,
    IoOptions,
    IoSave,
} from 'react-icons/io5'
import { CustomMenuItem } from '../../lib/entities/misc'
import { buildAreaPickerOptions, useWindowSizeUp } from '../../lib/utils'
import ButtonGroup from '@mui/material/ButtonGroup'

export interface QueryBuilderValues {
    pickerOneValue: any
    pickerTwoValue: any
    start_date: Date | null
    end_date: Date | null
}

interface QueryBuilderProps {
    organization: Organization
    pickerOneLabel?: string
    areaPickerLabel?: string
    pickerOnePlaceholder?: string
    areaPickerPlaceholder?: string
    pickerOneValue?: any
    pickerOneOptions: PickerItem<any>[]
    pickerOneMulti?: boolean
    pickerOneRestrictIfGroups?: boolean
    selectedAreas: string[]
    startDate: Date | null
    endDate: Date | null
    minDate: Date
    onRunQueryPress(values: QueryBuilderValues): void
    onResetQueryPress: React.MouseEventHandler
    onSaveQueryPress: React.MouseEventHandler
    onDownloadPress: React.MouseEventHandler
    detachedPickerLabels?: boolean
}

export const QueryBuilder: FC<QueryBuilderProps> = ({
    organization,
    pickerOneLabel,
    areaPickerLabel,
    pickerOnePlaceholder,
    areaPickerPlaceholder,
    pickerOneValue,
    pickerOneOptions,
    pickerOneMulti,
    pickerOneRestrictIfGroups,
    selectedAreas,
    startDate,
    endDate,
    minDate,
    onRunQueryPress,
    onResetQueryPress,
    onSaveQueryPress,
    onDownloadPress,
    detachedPickerLabels,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const lgScreens = useWindowSizeUp('lg')

    const [modalOpen, setModalOpen] = React.useState<boolean>(false)
    const [tempValues, setTempValues] = React.useState<QueryBuilderValues>({
        pickerOneValue,
        pickerTwoValue: selectedAreas,
        start_date: startDate,
        end_date: endDate,
    })
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const open = Boolean(anchorEl)

    const onOptionsClick = React.useCallback(
        (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget)
        },
        []
    )

    const onOptionsClose = useCallback(() => {
        setAnchorEl(null)
    }, [])

    React.useEffect(() => {
        if (lgScreens) {
            setModalOpen(false)
        }
    }, [lgScreens])

    useEffect(() => {
        setTempValues({
            pickerOneValue,
            pickerTwoValue: selectedAreas,
            start_date: startDate,
            end_date: endDate,
        })
    }, [pickerOneValue, selectedAreas, startDate, endDate])

    const updateQueryBuilderValue = useCallback(
        (payload: Partial<QueryBuilderValues>) => {
            setTempValues((tv) => ({
                ...tv,
                ...payload,
            }))
        },
        []
    )

    const onApplyQuery: React.MouseEventHandler = useCallback(
        (e) => {
            onRunQueryPress(tempValues)
            setModalOpen(false)
        },
        [onRunQueryPress, tempValues]
    )

    const areaOptions = useMemo(
        () => buildAreaPickerOptions(organization, tempValues.pickerTwoValue),
        [organization, tempValues.pickerTwoValue]
    )

    const renderQueryBuilderBody = (dialog = false) => (
        <Box
            className={localClasses.container}
            sx={{ py: dialog ? '0.5rem' : undefined }}
        >
            <div className={localClasses.queryGroup}>
                <Picker
                    inputLabel={pickerOneLabel}
                    placeholder={pickerOnePlaceholder}
                    value={tempValues.pickerOneValue}
                    onChange={(value) =>
                        updateQueryBuilderValue({ pickerOneValue: value })
                    }
                    data={pickerOneOptions}
                    smallLabel
                    id={`querybuilder-select-${(pickerOneLabel || '1')
                        .toLowerCase()
                        .replaceAll(' ', '-')}`}
                    fullWidth
                    detachedLabel={detachedPickerLabels}
                    multi={pickerOneMulti}
                    onlySelectOneGroup={pickerOneRestrictIfGroups}
                />
                <Picker
                    inputLabel={areaPickerLabel}
                    placeholder={areaPickerPlaceholder}
                    value={tempValues.pickerTwoValue}
                    onChange={(value) =>
                        updateQueryBuilderValue({ pickerTwoValue: value })
                    }
                    data={areaOptions}
                    smallLabel
                    id={`querybuilder-select-${(areaPickerLabel || '2')
                        .toLowerCase()
                        .replaceAll(' ', '-')}`}
                    fullWidth
                    detachedLabel={detachedPickerLabels}
                    multi
                    onlySelectOneGroup={false}
                />
            </div>
            <div className={localClasses.queryGroup}>
                <DatePicker
                    label={'From:'}
                    smallLabel
                    onChange={(date) =>
                        updateQueryBuilderValue({ start_date: date })
                    }
                    value={tempValues.start_date}
                    iconColor={colors.chips.PEROLOUS}
                    minDate={minDate}
                    maxDate={tempValues.end_date ?? undefined}
                    fullWidth
                    detachedLabel={detachedPickerLabels}
                />
                <DatePicker
                    label={'To:'}
                    smallLabel
                    onChange={(date) =>
                        updateQueryBuilderValue({ end_date: date })
                    }
                    value={tempValues.end_date}
                    iconColor={colors.chips.PEROLOUS}
                    minDate={tempValues.start_date ?? undefined}
                    fullWidth
                    detachedLabel={detachedPickerLabels}
                />
            </div>
            {lgScreens ? (
                <ButtonGroup
                    variant="contained"
                    className={localClasses.queryActionButton}
                >
                    <Button onClick={onApplyQuery}>Run Query</Button>
                    <Button
                        onClick={onOptionsClick}
                        size="small"
                        className={localClasses.queryOptionsButton}
                    >
                        <IoChevronDown
                            size={primaryIconSize}
                            aria-label="Open query options menu"
                        />
                    </Button>
                </ButtonGroup>
            ) : null}
        </Box>
    )

    const onCancelModalChanges: React.MouseEventHandler = (e) => {
        setTempValues({ ...tempValues })
        setModalOpen(false)
    }

    const queryActions: MenuItemProps[] = React.useMemo(() => {
        const actions: MenuItemProps[] = [
            {
                label: 'Reset Query',
                icon: <IoArrowUndo size={primaryIconSize} />,
                onClick: onResetQueryPress,
            },
            {
                label: 'Save Query',
                icon: <IoSave size={primaryIconSize} />,
                onClick: onSaveQueryPress,
            },
            {
                label: 'Download Report',
                icon: <IoDownload size={primaryIconSize} />,
                onClick: onDownloadPress,
            },
        ]
        if (!lgScreens) {
            actions.splice(0, 0, {
                label: 'Edit Query',
                icon: <IoOptions size={primaryIconSize} />,
                onClick: () => setModalOpen(true),
            })
        }
        return actions
    }, [onDownloadPress, onResetQueryPress, onSaveQueryPress, lgScreens])

    return (
        <div
            className={localClasses.outer}
            style={{ paddingTop: detachedPickerLabels ? '' : '.75rem' }}
        >
            {lgScreens ? (
                renderQueryBuilderBody()
            ) : (
                <>
                    <Dialog
                        title="Edit Query"
                        content={renderQueryBuilderBody(true)}
                        ctas={[
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onCancelModalChanges}
                                key="cancel"
                            >
                                Cancel
                            </Button>,
                            <Button
                                variant="contained"
                                onClick={onApplyQuery}
                                key="run-query"
                            >
                                Run Query
                            </Button>,
                        ]}
                        width="min(40rem, 90vw)"
                        open={modalOpen}
                        onClose={() => setModalOpen(false)}
                    />
                    <Button
                        variant="outlined"
                        onClick={onOptionsClick}
                        startIcon={<IoEllipsisVertical />}
                    >
                        Query Options
                    </Button>
                </>
            )}
            <Menu
                id="query-actions"
                anchorEl={anchorEl}
                open={open}
                onClose={onOptionsClose}
                MenuListProps={{
                    'aria-labelledby': 'options-button',
                }}
            >
                {queryActions.map((o) => (
                    <CustomMenuItem
                        key={o.label}
                        {...o}
                        onClick={(e) => {
                            o.onClick?.(e)
                            onOptionsClose()
                        }}
                    />
                ))}
            </Menu>
        </div>
    )
}

export const InsightsPageButtonsSkeleton = () => {
    const lgScreens = useWindowSizeUp('lg')

    return (
        <div style={{ display: 'flex', gap: '.25rem' }}>
            <Skeleton
                variant="rounded"
                sx={{
                    borderRadius: '3rem',
                    height: '2.5rem',
                    marginBottom: '.25rem',
                    width: lgScreens ? '8rem' : '11rem',
                }}
            />
            {lgScreens && (
                <Skeleton
                    variant="rounded"
                    sx={{
                        borderRadius: '3rem',
                        marginBottom: '.25rem',
                        height: '2.5rem',
                        width: '2.5rem',
                    }}
                />
            )}
        </div>
    )
}

interface QueryBuilderSkeletonProps {
    detachedPickerLabels?: boolean
}

export const QueryBuilderSkeleton: FC<QueryBuilderSkeletonProps> = ({
    detachedPickerLabels,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const lgScreens = useWindowSizeUp('lg')

    return (
        <div style={{ display: 'flex', alignItems: 'flex-end' }}>
            {lgScreens ? (
                <div className={localClasses.queryGroup}>
                    {[...Array(4)].map((_, idx) => (
                        <Stack key={idx} sx={{ flex: 1 }}>
                            {detachedPickerLabels && (
                                <Skeleton
                                    width={'2.5rem'}
                                    className={localClasses.pickerSkeletonLabel}
                                />
                            )}
                            <Skeleton
                                variant="rectangular"
                                className={localClasses.pickerSkeleton}
                            >
                                <DatePicker
                                    onChange={() => {}}
                                    value={new Date()}
                                />
                            </Skeleton>
                        </Stack>
                    ))}
                </div>
            ) : null}
            <InsightsPageButtonsSkeleton />
        </div>
    )
}

const useLocalStyles = makeStyles()({
    outer: {
        display: 'flex',
        gap: '1rem',
        alignItems: 'flex-end',
        justifyContent: 'space-between',
        width: '100%',
    },
    container: {
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '1fr',
        alignItems: 'flex-end',
        gap: '1rem',
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: '1fr 1fr auto',
        },
    },
    queryGroup: {
        display: 'flex',
        width: '100%',
        gap: '0.5rem',
        justifyContent: 'center',
        flexDirection: 'row',
        alignItems: 'flex-end',
        ':first-of-type': {
            marginRight: '1rem',
        },
        [theme.breakpoints.only('xs')]: {
            flexDirection: 'column',
        },
    },
    queryActionButton: {
        margin: '0.25rem 0',
        borderRadius: '1.5rem',
    },
    queryOptionsButton: {
        padding: '0 0.5rem',
    },
    pickerSkeleton: {
        borderRadius: '0.5rem',
        height: '3rem',
        minWidth: '100%',
    },
    pickerSkeletonLabel: {
        fontSize: '1.5rem',
        marginBottom: '.4rem',
    },
})
