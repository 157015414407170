import { AxiosResponse } from 'axios'
import Stripe from 'stripe'
import { isa } from '../api'
import { store } from '../store'
import { setNewPaymentMethodConfig } from '../slices/payment'

export const getNewPaymentMethodSession =
    async (): Promise<Stripe.SetupIntent> => {
        const response: AxiosResponse<Stripe.SetupIntent> = await isa.put(
            '/payment-method'
        )
        store.dispatch(
            setNewPaymentMethodConfig(response.data.client_secret ?? null)
        )
        return response.data
    }
