import Close from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { Breakpoint, useMediaQuery } from '@mui/material'
import React from 'react'
import hotToast, { Renderable } from 'react-hot-toast'
import voca from 'voca'

import {
    desktopMenuClosedWidth,
    desktopMenuOpenWidth,
    theme,
} from '../styles/universal'
import { format } from 'date-fns-tz'
import {
    InsightQueryDTO,
    InsightQueryType,
    PickerItem,
} from '@hazadapt-git/public-core-base'
import { isa } from '../api'
import { AxiosResponse } from 'axios'
import { UrlTransform } from 'react-markdown'
import { RootState, useAppSelector } from '../store'

/**
 * Matches with windows larger than or equal to the given breakpoint
 * @param size Breakpoint
 * @returns boolean
 */
export const useWindowSizeUp = (size: Breakpoint): boolean => {
    return useMediaQuery(theme.breakpoints.up(size))
}

/**
 * Matches with windows smaller than or equal to the given breakpoint
 * @param size Breakpoint
 * @returns boolean
 */
export const useWindowSizeDown = (size: Breakpoint): boolean => {
    return useMediaQuery(theme.breakpoints.down(size))
}

/**
 * Matches with windows exactly the given screen size threshold
 * @param size Breakpoint
 * @returns boolean
 */
export const useWindowSizeExact = (size: Breakpoint): boolean => {
    return useMediaQuery(theme.breakpoints.only(size))
}

/**
 * Matches with windows between the given the breakpoints
 * @param size Breakpoint
 * @returns boolean
 */
export const useWindowSizeBetween = (
    start: Breakpoint,
    end: Breakpoint
): boolean => {
    return useMediaQuery(theme.breakpoints.between(start, end))
}

/**
 * Styles for fullscreen content, responsive to sidebar menu state
 */
export const useResponsiveStyles = () => {
    const { desktopMenuOpen } = useAppSelector((state: RootState) => state.base)

    const responsiveStyles = {
        width: `calc(100% - ${
            desktopMenuOpen ? desktopMenuOpenWidth : desktopMenuClosedWidth
        })`,
        marginLeft: desktopMenuOpen
            ? desktopMenuOpenWidth
            : desktopMenuClosedWidth,
        transition: 'all 0.225s ease',
    }
    return responsiveStyles
}

/**
 * Display a toast message
 * @param message string
 * @param icon React.ReactNode
 */
export const toast = (message: string, icon?: Renderable): void => {
    hotToast(
        (t) => (
            <Typography
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width={300}
                maxWidth="80vw"
                p={0}
            >
                {message}
                <Close
                    color="secondary"
                    sx={{ cursor: 'pointer', marginLeft: '1rem' }}
                    onClick={() => hotToast.dismiss(t.id)}
                />
            </Typography>
        ),
        {
            icon,
        }
    )
}

/**
 * Sort the items in a picker alphabetically
 * @param a first item to compare
 * @param b second item to compare
 * @returns number
 */
export const sortPickerItemsAlphabetically = (
    a: PickerItem<any>,
    b: PickerItem<any>
) => {
    return a.label < b.label ? -1 : a.label > b.label ? 1 : 0
}

export const transformLinkUri: UrlTransform = (href: string): string => {
    return voca.lowerCase(href.replace('hazadapt://', '/'))
}

export const getUserPoint = () =>
    new Promise<[number, number] | undefined>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
            async (position) => {
                const { latitude, longitude } = position.coords
                resolve([latitude, longitude])
            },
            async (err) => {
                process.env.REACT_APP_DEPLOY_ENV !== 'production' &&
                    console.error(err)
                resolve(undefined)
            }
        )
    })

export const getAvatars = async (): Promise<string[]> => {
    const response: AxiosResponse<string[]> = await isa.get('/avatars')
    return response.data
}

export const formatDateRange = (
    start_date?: Date | string,
    end_date?: Date | string
): string => {
    const start =
        typeof start_date === 'string' ? new Date(start_date) : start_date
    const end = typeof end_date === 'string' ? new Date(end_date) : end_date
    if (start) {
        if (end) {
            return `${format(start, 'MMMM d, y')} - ${format(end, 'MMMM d, y')}`
        } else {
            return `${format(start, 'MMMM d, y')} - Present`
        }
    } else {
        if (end) {
            return `Through ${format(end, 'MMMM d, y')}`
        } else {
            return ''
        }
    }
}

export const buildQueryHref = (query: InsightQueryDTO): string => {
    const routeMap: Map<InsightQueryType, string> = new Map([
        [InsightQueryType.CORE, ''],
        [InsightQueryType.HAZARDS, 'hazard-guide'],
        [InsightQueryType.PREP_CHECKS, 'prep-checks'],
        [InsightQueryType.PREP_CHECK_QUESTION, 'prep-check-question'],
    ])
    const queryTypeRoute = routeMap.get(query.type) ?? InsightQueryType.CORE

    return queryTypeRoute === InsightQueryType.CORE
        ? '/home'
        : queryTypeRoute === InsightQueryType.PREP_CHECK_QUESTION
        ? `/insights/prep-checks/${queryTypeRoute}?query_id=${query.id}`
        : `/insights/${queryTypeRoute}?query_id=${query.id}`
}

export const toBase64 = (file: File) =>
    new Promise<string | undefined>((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result?.toString())
        reader.onerror = reject
    })

export function useForwardedRef<T>(ref: React.ForwardedRef<T>) {
    const innerRef = React.useRef<T>(null)

    React.useEffect(() => {
        if (!ref) return
        if (typeof ref === 'function') {
            ref(innerRef.current)
        } else {
            ref.current = innerRef.current
        }
    })

    return innerRef
}

/**
 * Truncates the chart label for a given value if it exceeds the specified maximum length.
 *
 * @param {number} value - The value for which the label needs to be truncated.
 * @param {number} maxLength - The maximum length of the truncated label.
 * @param {GetLabelForValueType} getLabelForValue - A function that retrieves the label for the given value.
 * @returns {string | number} - The truncated label if it exceeds the maxLength, otherwise the original label or value.
 *
 * @example
 * options={{
 *     scales: {
 *         y: {
 *             ticks: {
 *                 callback: function (value) {
 *                     return truncateLabel(
 *                         value as number,
 *                         10,
 *                         this.getLabelForValue.bind(this)
 *                     );
 *                 },
 *             },
 *         },
 *     },
 * }};
 */
export const truncateChartLabel = (
    value: number,
    maxLength: number,
    getLabelForValue: (value: number) => string | undefined
): string | number => {
    const label = getLabelForValue(value)
    if (typeof label === 'string') {
        return label.length > maxLength
            ? label.substring(0, maxLength) + '...'
            : label
    }
    return value
}

/**
 * Generates an array of hexcodes to generate a linear gradient by lightening and darkening a base color
 * @param {string} hexColor The base hex color to generate the gradient from.
 * @param {number} lightenAmount The amount to lighten the base color (optional, defaults to 100)
 * @param {number} darkenAmount The amount to darken the base color (optional, defaults to -100)
 * @returns {string[]} an array of colors to generate a linear gradient [lighterColor, hexColor, darkerColor]
 */
export const generateLinearGradientColors = (
    hexColor: string,
    lightenAmount: number = 100,
    darkenAmount: number = -100
): string[] => {
    const adjustHexColor = (color: string, amount: number): string => {
        let usePound = false

        if (color[0] === '#') {
            color = color.slice(1)
            usePound = true
        }

        const num = parseInt(color, 16)

        let r = (num >> 16) + amount
        if (r > 255) r = 255
        else if (r < 0) r = 0

        let g = ((num >> 8) & 0x00ff) + amount
        if (g > 255) g = 255
        else if (g < 0) g = 0

        let b = (num & 0x0000ff) + amount
        if (b > 255) b = 255
        else if (b < 0) b = 0

        return (
            (usePound ? '#' : '') +
            ((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')
        )
    }

    const lighterColor = adjustHexColor(hexColor, lightenAmount)
    const darkerColor = adjustHexColor(hexColor, darkenAmount)

    return [lighterColor, hexColor, darkerColor]
}

/**
 * Truncates a string to a new length and does not break words, truncated string will not be longer than supplied length
 * @param string string
 * @param length max length of truncated string, defaults to 30
 * @returns string
 */
export const pruneString = (string: string, length: number = 30) => {
    return voca.prune(string, length)
}

/**
 * Formats a Stripe timestamp to mm/dd/yyyy
 * @param timestamp number
 * @returns string
 */
export const formatStripeTimestamp = (timestamp: number | null) => {
    if (timestamp) {
        return new Date(timestamp * 1000).toLocaleDateString('en-US')
    } else return 'Invalid Date'
}

export const randomlyPickOne = (arr: any[]): any | undefined => {
    if (arr.length === 0) return undefined
    return arr[Math.floor(Math.random() * arr.length)]
}

export const getDeviceType = () => {
    return (
        // check for touchscreen functionality
        ('ontouchstart' in window || navigator.maxTouchPoints > 0) &&
            // as well as a mobile agent
            /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                navigator.userAgent
            )
            ? 'mobile'
            : 'desktop'
    )
}

export const getOperatingSystem = () => {
    const userAgent = window.navigator.userAgent.toLowerCase()
    if (userAgent.includes('win')) return 'windows'
    if (userAgent.includes('mac')) return 'mac'
    return 'other'
}

export const getDeviceDetails = () => {
    return { type: getDeviceType(), os: getOperatingSystem() }
}
