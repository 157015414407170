import cx from 'classnames'
import React, { FC, useMemo } from 'react'
import { MenuItemProps, RPPaymentMethod } from '../../lib/entities'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { IoEllipsisVertical } from 'react-icons/io5'
import { primaryIconSize } from '../../lib/styles/universal'
import { CustomMenuItem } from '../../lib/entities/misc'
import { colors } from '@hazadapt-git/public-core-base'
import { makeStyles } from 'tss-react/mui'
import { useBilling } from '../../lib/hooks/useBilling'
import { differenceInMonths, startOfMonth } from 'date-fns'

interface PaymentMethodDetailsProps extends RPPaymentMethod {
    actions?: MenuItemProps[]
}

export const PaymentMethodDetails: FC<PaymentMethodDetailsProps> = ({
    icon,
    type,
    last4,
    repr,
    actions = [],
}) => {
    const { classes: localClasses } = useLocalStyles()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const { getPaymentMethodExpirationDate } = useBilling()
    const open = Boolean(anchorEl)

    const onOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const onOptionsClose = () => {
        setAnchorEl(null)
    }

    const expirationDateStr = useMemo(
        () => getPaymentMethodExpirationDate(repr),
        [getPaymentMethodExpirationDate, repr]
    )

    const monthsTillExpiration = useMemo(() => {
        if (!expirationDateStr || !repr.card) return 12 // No expiration date, or not a card; return a month value that would not be flagged as expiring soon

        const expirationDate = new Date()
        expirationDate.setFullYear(repr.card.exp_year, repr.card.exp_month - 1)
        return differenceInMonths(startOfMonth(expirationDate), new Date())
    }, [repr, expirationDateStr])

    return (
        <div className={localClasses.container}>
            {icon}
            <Stack>
                <Typography fontWeight={500}>
                    {type}
                    {last4 ? ` - Ending in ${last4}` : null}
                </Typography>
                {expirationDateStr && (
                    <Typography
                        className={cx({
                            [localClasses.expiringSoon]:
                                monthsTillExpiration <= 6,
                        })}
                    >
                        {monthsTillExpiration >= 0 ? 'Expires' : 'Expired'}{' '}
                        {expirationDateStr}
                    </Typography>
                )}
            </Stack>
            {actions.length > 0 ? (
                <>
                    <IconButton
                        onClick={onOptionsClick}
                        className={localClasses.options}
                    >
                        <IoEllipsisVertical
                            size={primaryIconSize}
                            color={colors.grays.NOIR}
                        />
                    </IconButton>
                    <Menu
                        id="payment-method-options"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={onOptionsClose}
                    >
                        {actions.map((action) => (
                            <CustomMenuItem
                                {...action}
                                onClick={(e) => {
                                    action.onClick?.(e)
                                    onOptionsClose()
                                }}
                                key={action.label}
                            />
                        ))}
                    </Menu>
                </>
            ) : null}
        </div>
    )
}

const useLocalStyles = makeStyles()({
    container: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '1rem',
    },
    options: {
        alignSelf: 'flex-start',
        marginTop: '-0.5rem',
        position: 'absolute',
        top: '1rem',
        right: 0,
    },
    expiringSoon: {
        color: colors.primary.CORAL,
        fontWeight: 500,
    },
})
