import { FC, useCallback, useEffect, useState } from 'react'
import { NamespacedPageProps } from '../lib/entities'
import { LoadingView, StripeContainer } from '../components'
import Stripe from 'stripe'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { useAppDispatch, useAppSelector } from '../lib/store'
import { useNavigate } from 'react-router'
import { resetStripeOptions } from '../lib/slices/payment'
import { getNewPaymentMethodSession } from '../lib/utils/payment'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { IoAlertCircle, IoArrowBack } from 'react-icons/io5'
import { errorColor, primaryIconSize, theme } from '../lib/styles/universal'
import { toast, useWindowSizeUp } from '../lib/utils'
import Button from '@mui/material/Button'
import { makeStyles } from 'tss-react/mui'
import Stack from '@mui/material/Stack'
import { useBilling } from '../lib/hooks/useBilling'
import { useSearchParams } from 'react-router-dom'

interface NewPaymentMethodPageProps extends NamespacedPageProps {
    session?: Stripe.SetupIntent
}
const NewPaymentMethodPage: FC<NewPaymentMethodPageProps> = ({
    organization,
    user,
    loading,
}) => {
    const { classes: localClasses } = useLocalStyles()
    const stripe = useStripe()
    const dispatch = useAppDispatch()
    const elements = useElements()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const mdScreens = useWindowSizeUp('md')
    const { createPaymentMethod } = useBilling()

    const stripeOptions = useAppSelector((state) => state.payment.stripeOptions)

    const onSubmit: React.FormEventHandler = useCallback(
        async (e) => {
            e.preventDefault()
            try {
                if (!stripe || !elements || !stripeOptions?.clientSecret) {
                    // Stripe.js hasn't yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return
                }

                await elements?.submit()

                const result = await createPaymentMethod({
                    elements,
                    stripe,
                    clientSecret: stripeOptions.clientSecret,
                    returnUrl:
                        searchParams.get('return_url') ??
                        `${window.location.origin}/settings/subscriptions`,
                })

                if (result.error?.message) {
                    toast(
                        result.error.message,
                        <IoAlertCircle
                            color={errorColor}
                            size={primaryIconSize}
                        />
                    )
                }

                // NOTE: Post-checkout redirect is handled as Stripe webhook
            } catch (err) {
                console.error(err)
            }
        },
        [elements, stripe, stripeOptions, createPaymentMethod, searchParams]
    )

    const goBack = useCallback(() => {
        dispatch(resetStripeOptions())
        navigate(-1)
    }, [navigate, dispatch])

    if (!organization || !user || loading || !stripeOptions?.clientSecret)
        return <LoadingView />

    return (
        <Stack maxWidth="80rem">
            <Link
                component="button"
                onClick={goBack}
                variant="h4"
                underline="none"
            >
                <IoArrowBack size={primaryIconSize} />
                Back
            </Link>
            <Typography
                component="h1"
                variant={mdScreens ? 'h2' : 'h3'}
                my="1rem"
            >
                Add New Payment Method
            </Typography>
            <form onSubmit={onSubmit} className={localClasses.paymentForm}>
                <PaymentElement options={{ layout: 'accordion' }} />
                <Button
                    variant="contained"
                    type="submit"
                    fullWidth={!mdScreens}
                    size="large"
                    className={localClasses.submitCta}
                >
                    Save Payment Method
                </Button>
            </form>
        </Stack>
    )
}

const NewPaymentMethodPageWrapper: FC<NewPaymentMethodPageProps> = ({
    organization,
    user,
    loading,
}) => {
    const stripeOptions = useAppSelector((state) => state.payment.stripeOptions)
    const [session, setSession] = useState<Stripe.SetupIntent>()

    useEffect(() => {
        if (!organization || !user) return
        getNewPaymentMethodSession().then(setSession).catch(console.error)
    }, [organization, user])

    if (!stripeOptions?.clientSecret) return <LoadingView />
    return (
        <StripeContainer options={stripeOptions}>
            <NewPaymentMethodPage
                organization={organization}
                user={user}
                loading={loading}
                session={session}
            />
        </StripeContainer>
    )
}

const useLocalStyles = makeStyles()({
    paymentForm: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
        width: '100%',
    },
    submitCta: {
        maxWidth: theme.breakpoints.values.sm,
        [theme.breakpoints.up('md')]: {
            alignSelf: 'flex-end',
        },
    },
})

export default NewPaymentMethodPageWrapper
