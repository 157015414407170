import React, {
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
    IoAlertCircle,
    IoCheckmarkCircle,
    IoOpenOutline,
} from 'react-icons/io5'
import { useElementWidthUp } from '../lib/hooks/useElementMediaQuery'
import { useNavigate, useParams } from 'react-router'
import {
    errorColor,
    primaryIconSize,
    successColor,
} from '../lib/styles/universal'
import { AddSeatsTriggerAndDrawer } from '../components/organisms/AddSeatsTriggerAndDrawer'
import { colors, OrganizationWithMembers } from '@hazadapt-git/public-core-base'
import { cancelSubscription, toast } from '../lib/utils'
import { SimplifiedSeatSubscription, useBilling } from '../lib/hooks/useBilling'
import { Dialog } from '../components/atoms'
import { ManageSubscriptionsTemplate } from '../components'
import Stripe from 'stripe'

interface ManageSubscriptionsPageProps {
    organization: OrganizationWithMembers
    subscriptionType: 'core' | 'seat'
}

export const ManageSubscriptionsPage: FC<ManageSubscriptionsPageProps> = ({
    organization,
    subscriptionType,
}) => {
    const [subToCancel, setSubToCancel] = useState<Stripe.Subscription | null>(
        null
    )
    const [checked, setChecked] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [unresolvedSeatCount, setUnresolvedSeatCount] = useState<number>(0)
    const containerRef = useRef(null)
    const mdElement = useElementWidthUp(containerRef, 'md')
    const navigate = useNavigate()
    const { rootSubscription, seatSubscriptions } = useBilling()
    const { '*': route } = useParams()

    const currentFlowPage = useMemo(() => {
        switch (route) {
            case 'cancel':
                return 'cancel'
            case 'success':
                return 'success'
            default:
                return 'manage'
        }
    }, [route])

    useEffect(() => {
        document.title = 'Subscriptions and Billing - ResiliencePoint'
    }, [])

    useEffect(() => {
        if (
            rootSubscription?.metadata.status === 'active' &&
            subscriptionType === 'core'
        )
            setSubToCancel(rootSubscription)
        // route protection
        if (rootSubscription?.metadata.status === 'pending_cancellation') {
            navigate('/settings/subscriptions', { replace: true })
        }
        // if user refreshes partway through cancel seat flow, send them back to the beginning
        if (subToCancel === null && subscriptionType === 'seat') {
            navigate('/settings/subscriptions/seats', { replace: true })
        }
    }, [navigate, subToCancel, subscriptionType, rootSubscription])

    const handleNavigateToCancelSeats = (sub: SimplifiedSeatSubscription) => {
        const fullSub = seatSubscriptions?.find((s) => s.id === sub.id)
        if (fullSub) {
            setSubToCancel(fullSub)
            navigate('/settings/subscriptions/seats/cancel')
        }
    }

    const handleNavigateToSeats = () => {
        navigate('/settings/subscriptions/seats')
    }

    const handleNavigateToSubs = () => {
        navigate('/settings/subscriptions')
    }

    const handleNavigateToOrg = () => {
        navigate('/settings/organization')
    }

    const handleCancelSubscription = useCallback(
        async (sub: Stripe.Subscription) => {
            if (!sub) return
            cancelSubscription(sub.id)
                .then(() => {
                    // if sub to be cancelled is a seat, check for unresolved metadata and handle it if necessary
                    if (subscriptionType === 'seat') {
                        const unresolved = sub?.metadata.unresolved
                        if (unresolved && unresolved !== '{}') {
                            try {
                                const parsed = JSON.parse(unresolved)
                                if (parsed.seats > 0) {
                                    setUnresolvedSeatCount(parsed.seats)
                                    setModalOpen(true)
                                    return
                                }
                            } catch (err) {
                                console.error(err)
                            }
                        }
                    }
                    // if sub to be cancelled is a core or seat w/out unresolved metadata, proceed with success actions
                    toast(
                        `A confirmation email has been sent to all organization administrators.`,
                        <IoCheckmarkCircle
                            color={successColor}
                            size={primaryIconSize}
                        />
                    )
                    navigate(
                        `/settings/subscriptions/${subscriptionType}/success`
                    )
                })
                .catch((err) => {
                    console.error(err)
                    toast(
                        `There was a problem cancelling your subscription. Please try again or contact support.`,
                        <IoAlertCircle
                            color={errorColor}
                            size={primaryIconSize}
                        />
                    )
                })
        },
        [navigate, subscriptionType]
    )

    const renderMainCTA = () => {
        switch (currentFlowPage) {
            case 'manage':
                return <AddSeatsTriggerAndDrawer buttonText="Add Seats" />
            case 'cancel':
                return (
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={() => {
                            if (subToCancel)
                                handleCancelSubscription(subToCancel)
                        }}
                        disabled={!checked}
                        sx={{
                            maxWidth: 'fit-content',
                            maxHeight: '2.5rem',
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Proceed To Cancel
                    </Button>
                )
            case 'success':
                return (
                    <>
                        {subscriptionType === 'seat' ? (
                            <Button
                                onClick={handleNavigateToSeats}
                                sx={{ maxWidth: 'fit-content' }}
                            >
                                Back To Seats
                            </Button>
                        ) : (
                            <Button
                                onClick={handleNavigateToSubs}
                                sx={{ maxWidth: 'fit-content' }}
                            >
                                Back To Subscriptions
                            </Button>
                        )}
                    </>
                )
        }
    }

    return (
        <Stack gap={'2rem'} paddingBottom={'4rem'} ref={containerRef}>
            {/* Page Description */}
            <Stack gap={'2rem'} width={'100%'}>
                <Stack
                    direction={mdElement ? 'row' : 'column'}
                    gap={mdElement ? 0 : '1rem'}
                    justifyContent={'space-between'}
                >
                    <Typography
                        variant={mdElement ? 'h2' : 'h3'}
                        component="h1"
                    >
                        {currentFlowPage === 'manage'
                            ? 'Edit Seats Subscription'
                            : 'Edit Subscription - Cancel Subscription'}
                    </Typography>
                    {renderMainCTA()}
                </Stack>
                <Typography
                    variant="body2"
                    color={colors.grays.CHARCOAL}
                    maxWidth={'43rem'}
                >
                    Please allow up to 24 hours for changes to your
                    ResiliencePoint subscription to take effect.{' '}
                    {currentFlowPage === 'manage'
                        ? 'Changes to your seats subscription do not affect your ResiliencePoint subscription.'
                        : 'Changes to you subscription do not affect any extra seats that have been purchased.'}{' '}
                    To make changes to any seats outside of the subscription,
                    please do so from the{' '}
                    {currentFlowPage === 'manage' ? (
                        <Link href="/settings/subscriptions" target="_blank">
                            Subscriptions & Billing Page <IoOpenOutline />
                        </Link>
                    ) : (
                        <Link
                            href="/settings/subscriptions/seats"
                            target="_blank"
                        >
                            Seats Page <IoOpenOutline />
                        </Link>
                    )}
                </Typography>
            </Stack>
            {/* Unresolved seats modal */}
            <Dialog
                open={modalOpen}
                onClose={() => setModalOpen(false)}
                title="Subscription has unresolved seats"
                ctas={[
                    <Button
                        key={'remove-members'}
                        onClick={handleNavigateToOrg}
                    >
                        Remove member{unresolvedSeatCount === 1 ? null : 's'}
                    </Button>,
                ]}
                content={
                    <>
                        <Typography>
                            Cancelling this subscription will result in your
                            organization containing more team members than
                            available seats.
                        </Typography>
                        <Typography>
                            Please remove {unresolvedSeatCount} team member
                            {unresolvedSeatCount === 1 ? ' ' : 's '}
                            before cancelling.
                        </Typography>
                    </>
                }
            />
            {/* Page Content */}
            <ManageSubscriptionsTemplate
                page={currentFlowPage}
                organization={organization}
                onNavigateToCancelSeats={handleNavigateToCancelSeats}
                checked={checked}
                setChecked={setChecked}
                subscriptionType={subscriptionType}
            />
        </Stack>
    )
}
