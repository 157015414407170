import { colors } from '@hazadapt-git/public-core-base'
import { format } from 'date-fns-tz'
import React, { FC } from 'react'
import { primaryIconSize } from '../../lib/styles/universal'
import { IoReload } from 'react-icons/io5'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Skeleton from '@mui/material/Skeleton'

interface LastUpdatedWithReloadProps {
    date?: Date
    onReload(): void
}

export const LastUpdatedWithReload: FC<LastUpdatedWithReloadProps> = ({
    date,
    onReload,
}) => {
    const formattedDate = date ? format(date, 'M/d/y @ h:mm aaa') : undefined

    return (
        <>
            {formattedDate ? (
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    alignItems={'center'}
                >
                    <IconButton onClick={onReload}>
                        <IoReload
                            color={colors.chips.PEROLOUS}
                            size={primaryIconSize}
                            aria-label="Refresh insight data"
                        />
                    </IconButton>
                    <Typography variant="body1" fontWeight={500}>
                        Last Updated: {formattedDate}
                    </Typography>
                </Box>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        minWidth: '20rem',
                    }}
                >
                    <Skeleton
                        variant="circular"
                        width={'3rem'}
                        height={'2.5rem'}
                    />
                    <Skeleton
                        variant="rounded"
                        width={'100%'}
                        sx={{ margin: 'auto 0 auto .25rem' }}
                    />
                </div>
            )}
        </>
    )
}
