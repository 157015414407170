import Box from '@mui/material/Box'
import React, { FC } from 'react'

interface LinearGradientProps {
    height: string | number
    width: string | number
    content?: React.ReactNode
    colors: string[]
}

export const LinearGradient: FC<LinearGradientProps> = (
    props: LinearGradientProps
) => {
    return (
        <Box
            sx={{
                height: props.height,
                width: props.width,
                background: `linear-gradient(to right${props.colors
                    .map((c) => `, ${c}`)
                    .join('')})`,
            }}
        >
            {props.content ?? null}
        </Box>
    )
}
