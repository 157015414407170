import { InsightQueryType, PickerItem } from '@hazadapt-git/public-core-base'
import React, { FC, useMemo } from 'react'

import { HazardGuideInsightsPageTemplate, LoadingView } from '../components'
import { NamespacedPageProps } from '../lib/entities'
import { useAppSelector } from '../lib/store'
import { getHazardData } from '../lib/utils/cms'
import { useInsights } from '../lib/hooks/useInsights'

interface HazardGuideInsightsProps extends NamespacedPageProps {}

const HazardGuideInsights: FC<HazardGuideInsightsProps> = ({
    user,
    organization,
    loading,
}) => {
    const {
        insights,
        activeQuery,
        quickStatRowTitle,
        retrievingInsights,
        onReloadPress,
        onResetQueryPress,
        onRunQueryPress,
        onSaveQuery,
        selectedDatesString,
        onDownloadReportPress,
        selectedAreas,
        selectedAreaName,
    } = useInsights({
        organization,
        type: InsightQueryType.HAZARDS,
    })

    // Store data
    const hazards = useAppSelector((state) => state.content.hazards)
    const hasLocalContent = useAppSelector(
        (state) => state.content.has_local_content
    )
    const selectedHazard = useMemo(() => {
        if (!activeQuery?.hazards || activeQuery.hazards.length === 0)
            return undefined
        const hazard = hazards.find((h) => h.id === activeQuery?.hazards?.[0])
        return hazard
    }, [activeQuery, hazards])

    React.useEffect(() => {
        document.title = 'Hazard Guide Activity - ResiliencePoint'
    }, [])

    React.useEffect(() => {
        getHazardData()
    }, [organization])

    const buildSelectedQueryString = () => {
        const selectedHazard = hazards.find(
            (h) => h.id === activeQuery?.hazards?.[0]
        )
        return `${selectedHazard?.name ?? 'All HazAdapt Hazard'} Guide Usage`
    }

    const hazardOptions: PickerItem<number | undefined>[] = useMemo(
        () => [
            { label: 'All Hazards', value: undefined },
            ...[...hazards]
                .sort((a, b) => (a.name < b.name ? -1 : 1))
                .map((h) => ({ label: h.name, value: h.id })),
        ],
        [hazards]
    )

    return organization && user ? (
        <HazardGuideInsightsPageTemplate
            pageTitle={`Hazard Guide Activity`}
            organization={organization}
            selectedQueryItem={activeQuery?.hazards?.[0]}
            queryItems={hazardOptions}
            queryItemTitle="Hazard"
            queryItemSelectorPlaceholder="All Hazards"
            selectedAreas={selectedAreas}
            areaSelectorPlaceholder="Full Jurisdiction"
            queryId={activeQuery?.id}
            startDate={
                activeQuery?.start_date
                    ? new Date(activeQuery.start_date)
                    : null
            }
            endDate={
                activeQuery?.end_date ? new Date(activeQuery.end_date) : null
            }
            onReloadPress={onReloadPress}
            updatedDate={
                activeQuery?.date_last_used
                    ? new Date(activeQuery.date_last_used)
                    : undefined
            }
            onRunQueryPress={onRunQueryPress}
            onResetQueryPress={onResetQueryPress}
            onSaveQueryPress={onSaveQuery}
            onDownloadPress={onDownloadReportPress}
            selectedQueryString={buildSelectedQueryString()}
            selectedLocationString={selectedAreaName}
            selectedDatesString={selectedDatesString}
            minQueryDate={new Date(organization.date_created)}
            insights={insights}
            quickStatRowTitle={quickStatRowTitle}
            loading={loading || retrievingInsights}
            showLocalContentDisclaimer={
                hasLocalContent && // There are local content
                activeQuery?.zips?.length === 0 && // Not searching over a specific ZIP code
                (!selectedHazard || !!selectedHazard.local) // No hazard selected, or hazard is a local hazard
            }
        />
    ) : (
        <LoadingView />
    )
}
export default HazardGuideInsights
